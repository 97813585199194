// Poppins font
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&family=Roboto:wght@100;300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

// Bootstrap
@import 'bootstrap/dist/css/bootstrap.min.css';

@import 'partials/fonts';
@import 'partials/variables';
@import 'partials/base';
@import 'partials/button';

@import 'partials/login';
@import 'partials/header';
@import 'partials/form';
@import 'partials/common';
@import 'partials/subscription';
@import 'partials/payment';
@import 'partials/dashboard';
@import 'partials/table';
@import 'partials/modal';
@import 'partials/tank';
@import 'partials/sidebar';
@import 'partials/loader';
@import 'partials/dashboardWelcome';
@import 'partials/graph';
@import 'partials/landingpage';
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif !important;
  padding: 0;
  margin: 0;
  background-color: #0f101a;
  *::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    @media (max-width: $medium) {
      display: none;
    }
  }

  *::-webkit-scrollbar-thumb {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background: #21222d !important;
  }
  *::-webkit-scrollbar:vertical {
    width: 12px;
    @media (max-width: $medium) {
      display: none;
    }
  }
  *::-webkit-scrollbar-track:vertical {
    background-color: #21222d;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
  }
  *::-webkit-scrollbar-thumb:vertical {
    -webkit-border-radius: 10px;
    background: $grey !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.loader-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  cursor: pointer;
}
.spinner-border {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  color: #fff;
  z-index: 9999;
}
