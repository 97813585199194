* {
  box-sizing: border-box;
}
.pl-4 {
  padding-left: 4px !important;
}
.pl-5 {
  padding-left: 5px !important;
}
.pl-15 {
  padding-left: 15px !important;
}
.pl-20 {
  padding-left: 20px !important;
}
.pl-30 {
  padding-left: 30px !important;
  z-index: 9999;
}
.pl-40 {
  padding-left: 40px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}
.pb-40 {
  padding-bottom: 40px !important;
}

.pr-4 {
  padding-right: 4px !important;
}
.pr-5 {
  padding-right: 5px !important;
}
.pr-15 {
  padding-right: 15px !important;
}
.pr-25 {
  padding-right: 25px !important;
}
.pr-30 {
  padding-right: 30px !important;
}
.pr-40 {
  padding-right: 40px !important;
}
.pt-15 {
  padding-top: 15px !important;
}
.pt-20 {
  padding-top: 20px !important;
}
.pt-24 {
  padding-top: 24px !important;
}
.pt-30 {
  padding-top: 30px !important;
}
.pt-40 {
  padding-top: 40px !important;
}

.mt-3 {
  margin-top: 3px !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-24 {
  margin-top: 24px !important;
}
.mt-25 {
  margin-top: 25px !important;
}
.mt-27 {
  margin-top: 27px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.ml-3 {
  margin-left: 3px !important;
}
.ml-4 {
  margin-left: 4px !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.ml-8 {
  margin-left: 8px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.ml-15 {
  margin-left: 15px !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.ml-25 {
  margin-left: 25px !important;
}
.ml-30 {
  margin-left: 30px !important;
}

.mr-5 {
  margin-right: 5px !important;
}
.mr-8 {
  margin-right: 8px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.mr-12 {
  margin-right: 12px !important;
}
.mr-15 {
  margin-right: 15px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.mr-25 {
  margin-right: 25px !important;
}
.mr-30 {
  margin-right: 30px !important;
}
.mr-40 {
  margin-right: 40px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}
.mb-6 {
  margin-bottom: 6px !important;
}
.mb-8 {
  margin-bottom: 8px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-12 {
  margin-bottom: 12px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.mb-25 {
  margin-bottom: 25px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-36 {
  margin-bottom: 36px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.text-truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.font-medium {
  font-weight: 500 !important;
}
.zindex {
  z-index: 9999;
}
.btn-shadow {
  box-shadow: 0 2px 4px 0 rgba(242, 2, 2, 0.3) !important;
}
.two-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-decoration {
  text-decoration: underline;
}
.manage-three-dot {
  font-size: 16px;
  button {
    left: 0 !important;
    right: 0 !important;
    margin: auto;
  }
}
.heading-bold {
  font-weight: 600;
}
.border-top {
  border-top: 1px solid #353535 !important;
}
.height-auto {
  height: auto !important;
}
.height20 {
  height: 20px;
}
.height28 {
  height: 28px;
}
.height36 {
  height: 36px;
}
.height50 {
  height: 50px;
}
.width33 {
  width: 33%;
}
.width47 {
  width: 47%;
}
.width-140 {
  width: 140px;
}
.width-240 {
  width: 240px;
}
.width-290 {
  width: 290px;
}

.w-xs-100 {
  @media (max-width: 576px) {
    width: 100% !important;
  }
}
.width-auto {
  width: auto !important;
}
.align-center {
  align-items: center;
}

.loader-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  cursor: pointer;
}
.spinner-border {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  color: #fff;
  z-index: 9999;
}
// toast css
.toast-common {
  .Toastify__toast {
    font-size: 14px;
    line-height: 17px;
    border-radius: 7px;
    margin-bottom: 0;
    min-height: 50px;
    cursor: default !important;
    @media (max-width: $medium) {
      // height: 110px;
      margin: 0 !important;
      border-radius: 0 !important;
    }
    @media (min-width: $small-medium) and (max-width: $medium) {
      height: 60px;
      border-radius: 7px;
    }
  }
  .Toastify__toast--success {
    background-color: #255827 !important;
    color: #fff;
    border: 0 !important;
    @media (max-width: $medium) {
      font-size: 16px;
      border-radius: 0 !important;
    }
    @media (min-width: $small-medium) and (max-width: $medium) {
      border-radius: 7px !important;
    }
    svg {
      fill: #fff;
    }
  }
  .Toastify__toast--error {
    background-color: #cc3333 !important;
    color: #fff;
    font-size: 13px;
    border-radius: 8px;
    border: 0 !important;
    @media (max-width: $medium) {
      font-size: 16px;
      border-radius: 0 !important;
    }
    @media (min-width: $small-medium) and (max-width: $medium) {
      border-radius: 8px !important;
    }
    svg {
      fill: #fff;
    }
  }
  .Toastify__close-button--light {
    color: #efefef !important;
    opacity: 1;
    display: none;
  }
  .Toastify__close-button {
    align-self: center;
  }
  .Toastify__toast-body {
    div {
      line-height: normal;
      color: #efefef;
    }
  }
  .Toastify__progress-bar {
    background-color: transparent !important;
  }
}
.Toastify__toast-container--top-right {
  top: 7px !important;
  left: 0;
  min-width: 250px;
  max-width: 500px;
  width: 100% !important;
  margin: auto;
  display: flex;
  justify-content: center;
  @media (max-width: $medium) {
    margin: 0 !important;
    top: 0 !important;
    min-width: 100%;
    max-width: 100%;
    width: 100% !important;
    display: table-header-group;
  }
  @media (min-width: $small-medium) and (max-width: $medium) {
    top: 7px !important;
    left: 0;
    min-width: 250px;
    max-width: 500px;
    width: 100% !important;
    margin: auto !important;
    display: flex;
    justify-content: center;
  }
}

// upload file css
.admin-fileUploadInput {
  display: grid;
  grid-gap: 10px;
  position: relative;
  z-index: 1;
  input {
    position: relative;
    z-index: 1;
    height: 44px;
    margin: 0;
    border-radius: 7px;
    background-color: transparent;
    color: #fff;
    font-size: 14px;
    padding-right: 140px;
    display: flex;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  button {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 44px;
    width: 135px;
    line-height: 0;
    border-radius: 0 7px 7px 0;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    border: 1px solid #4F505C;
    z-index: 1;
    background-color: #4F505C;
    &:active,
    &:hover,
    &:focus {
        background-color: #4F505C !important;
        color: #fff;
    }
  }
  input[type=file] {
    padding: 0 gap(m);
    &::-webkit-file-upload-button {
      visibility: hidden;
      margin-left: 10px;
      padding: 0;
      height: 50px;
      width: 0px;
    }
  }
  .upload-icon {
    margin-right: 6px;
  }
}
.upload-info {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #cc3333;
  .showHand {
    cursor: pointer;
  }
}
.icon-logout-new, .icon-username, .icon-lock {
  font-size: 17px;
}
.address-options {
  background-color: #393a42 !important;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 991;
  border-radius: 7px;
  min-height: 100px;
  height: 200px;
  overflow-y: auto;
  .address-options-items {
    color: #FFF;
    font-family: 'Roboto' ,sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    display: flex;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #4a4b50;
    cursor: pointer;
    &:hover {
      background-color: #4a4b50 !important;
    }
  }
}
.height44 {
  height: 44px !important;
}

.cursor-default{
  cursor: default !important;
}