// breadcrum css
.custom-breadcrum {
  .breadcrumb {
    margin-bottom: 27px;
  }
  .breadcrumb-item {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    a {
      color: $grey;
      text-transform: capitalize;
    }
    &:last-child {
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
      margin-left: 0px;
      a {
        color: $white;
      }
      &::before {
        margin-right: 0px;
        color: #aaabae;
      }
    }
  }
  .breadcrumb-item + .breadcrumb-item::before {
    color: #aaabae;
  }
}

// tank detail css
.tank-detail-sec {
  .probes-detail-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
    color: #7B7B7B;
    margin-bottom: 18px;
  }
  .tank-detail-wrapper {
    display: flex;
    margin-top: 35px;
    @media (max-width: $medium) {
      display: unset;
    }
    .tank-detail-left {
      max-width: 250px;
      width: 100%;
      min-height: 268px;
      background-color: #21222d;
      border-radius: 7px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: $medium) {
        max-width: 100%;
      }
    }
    .tank-detail-right {
      margin-left: 20px;
      width: 100%;
      @media (max-width: $medium) {
        margin-left: 0;
        margin-top: 20px;
      }
    }
  }
  .tank-detail-left {
    max-width: 250px;
    width: 100%;
    min-height: 268px;
    background-color: #21222d;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: $medium) {
      max-width: 100%;
    }
  }
}
// probes catalogue scss
.probes-detail-top {
  background-color: #21222d;
  padding: 39px 27px;
  border-radius: 7px;
  margin-top: 31px;
  @media (max-width: $medium) {
    padding: 20px;
  }
  .probes-detail-top-row {
    align-items: center;
    position: relative;
    .probes-detail-left {
      .probes-subtitle {
        font-size: 21px;
        font-weight: 700;
        line-height: 25px;
        color: $white;
      }
      .detail-subtexts {
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        color: #C9C9C9;
        margin-top: 9px;
      }
    }
    .probes-detail-middle {
      @media (max-width: $medium) {
        margin: 30px 0;
      }
      @media (min-width: $small-medium) and (max-width: $medium) {
        margin: 0;
      }
      .detail-title {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: #aaabae;
      }
      .detail-subtext2 {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: #fff;
        margin-top: 7px;
        word-break: break-word;
      }
    }
    .probes-detail-right {
      text-align: -webkit-right;
      @media (max-width: $medium) {
        text-align: -webkit-right;
      }
      .btn-status {
        height: 27px;
        font-weight: 600;
      }
    }
  }
}
.probes-detail-middle-mobile {
  @media (max-width: $medium) {
    margin-bottom: 0 !important;
  }
}
.probes-detail-right-mobile {
  @media (max-width: $medium) {
    position: absolute;
    top: 7px;
    right: 0px;
  }
}
.probes-detail-sec {
  margin-top: 45px;
  .probes-detail-wrapper {
    margin-top: 0;
  }
}

.common-detail-wrapper {
  background-color: #21222d;
  padding: 27px;
  border-radius: 7px;
  @media (max-width: $medium) {
    margin-left: 0;
    padding: 20px;
  }
  .common-detail-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    color: $white;
    margin-bottom: 37px;
  }
  .list-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;

  }
  .list-group-item {
    background-color: transparent;
    border: 0;
    padding: 0;
    width: 33%;
    margin-bottom: 37px;
    @media (max-width: $medium) {
      margin-right: 0;
      margin-bottom: 30px;
      &:nth-child(1) {
        width: 50%;
      }
      &:nth-child(2) {
        width: 50%;
      }
    }
    @media (min-width: $small-medium) and (max-width: $medium) {
      &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5) {
        width: 50%;
      }
    }
    .detail-title {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      color: #aaabae;
    }
    .detail-subtext2 {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      color: #fff;
      margin-top: 7px;
      word-break: break-word;
    }
    .list-group {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      width: 100%;
    }
    .list-group-item {
      background-color: transparent;
      border: 0;
      padding: 0 20px 0 0;
      margin-bottom: 37px;
      width: 33%;
      @media (max-width: $medium) {
        margin-right: 0;
        margin-bottom: 30px;
        &:nth-child(1) {
          width: 50%;
        }
        &:nth-child(2) {
          width: 50%;
        }
      }
      .detail-title {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: #aaabae;
      }
      .detail-subtext {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: #fff;
        margin-top: 7px;
        word-break: break-word;
      }
    }
  }
}
.manage-subscription-header {
  display: block;
  @media (max-width: $large) {
    display: none;
  }
}
.for-desktop {
  display: block;
  @media (max-width: $large) {
    display: none;
  }
}
.for-mobile {
  display: none;
  @media (max-width: $medium) {
    display: block;
  }
}
.for-ipad {
  display: none;
  @media (min-width: $small-medium) and (max-width: $medium) {
      display: block !important;
  }
}
.display-none {
  display: none;
}
@media (max-width: $medium) {
  .header-right-mobile {
    display: block;
    margin-top: 30px;
    .header-outline-btn {
      width: 100%;
      margin-bottom: 20px;
    }
    .header-primary-btn {
      width: 100%;
    }
  }
  .header-wrapper-mobile {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    position: relative;
    .admin-header-title {
      font-size: 20px;
      line-height: 23px;
    }
    .left-arrow {
      position: absolute;
      left: 0;
      cursor: pointer;
    }
  }
}
@media (min-width: $small-medium) and (max-width: $medium) {
  .header-right-mobile {
    display: flex;
  }
}
.pick-on-click {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.tank-detail-listing {
  .list-group {
    width: 100%;
  }
  .list-group-item {
    &:nth-child(4) {
      width: 100%;
    }
    @media (max-width: $medium) {
      &:nth-child(3),
      &:nth-child(4) {
        width: 100%;
      }
    }
  }
  .detail-subtext {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #fff;
    margin-top: 7px;
    word-break: break-word;
  }
}

.tanks-infinite {
  overflow: unset !important;
}
.probe-detail-new {
  .list-group-item {
    @media (max-width: $medium) {
      &:nth-child(1) {
        width: 100%;
        margin-bottom: 30px !important;
      }
      &:nth-child(2) {
        width: 100%;
      }
    }
    @media (min-width: $small-medium) and (max-width: $medium) {
      &:nth-child(1) {
        width: 50%;
      }
      &:nth-child(2) {
        width: 50%;
      }
    }
  }
}
.probe-wrapper-mobile {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  position: relative;
  align-items: center;
  .dashboard-title {
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    margin-bottom: 0 !important;
    text-align: center;
    span {
      font-size: 16px !important;
      font-weight: 500 !important;
      line-height: 19px !important;
      display: block;
    }
  }
  .left-arrow {
    position: absolute;
    left: 0;
    cursor: pointer;
  }
}