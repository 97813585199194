/*=============================================
*   screen width
=============================================*/
$small: 576px;
$small-medium: 767px;
$medium: 992px;
$large: 1023px;
$extra-large: 1200px;
$extra-extra-large: 1400px;

$sidebar-width: 283px;

//-----------Custom variables will goes here
$white:                                         #FFFFFF;
$black:                                         #000000;
$grey :                                         #AAABAE;
$danger:                                        #FF6060;

$theme-color:                                   #21222D !default;
$theme-secondary-color:                         #14151F !default;
$theme-text:                                    #222222 !default;
$theme-secondary-text:                          #000000 !default;
$secondary-para-text:                           #828282 !default;
$theme-main-background:                         #f5f6fa !default;

$link-color:                                    #0F5D99 !default;
$main-border-color:                             #e1e1e1 !default;

// Button
$primary-button-background:                      #0F5D99 !default;
$primary-button-text:                            #FFFFFF !default;
$btn-link-background:                            #FFFFFF !default;
$btn-link-text:                                  #0F5D99 !default;
$btn-link-border:                                #0F5D99 !default;


$input-background:                              #FFFFFF;
$input-border-color:                            #E9ECEF;
$input-border-active-color:                     #e1e7f6;
$input-placeholder:                             #C5C5C5;
$input-text:                                    #000000;
$input-label-color:                             #222;
$error-color:                                   #FF6060;
$input-focus-border-color:                      #1570EF;
$email-color: 									#5e5f63;


// Modal
$modal-header-background:                       #f7faff;


// Checkbox and radio
$checkbox-background:                           #fff;
$checkbox-checked-background:                   #0F5D99;
$big-radio-border:                              #e9e9e9;
$terms-check-label-color:                       #828282;
$breadcrumb-seprator-color:                     #131b42;


// dashboard
$left-nav-background:                            linear-gradient(180deg, #DFF6FF 0%, rgba(223, 246, 255, 0.00) 100%);
$left-nav-active-background:                     #47B5FF;
$left-nav-icons-color:                           #98A2B3;
$nav-icons-color:                                #101828;
$nav-icons-active-color:                         #2F80ED;

$card-dropdown-icon:                             #d0d1d7;



// Pages
$nav-icon-color:                $left-nav-icons-color;
$nav-active-bg:                 $left-nav-active-background;
