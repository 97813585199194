.payment-sec {
  margin: 53px;
  min-height: calc(100% - 250px);
  @media (max-width: $medium) {
    margin: 20px;
  }
}
.payment-col-right {
  @media (max-width: $medium) {
    margin-top: 30px;
  }
  @media (min-width: $small-medium) and (max-width: $medium) {
    width: 100%;
  }
}
.payment-title {
  font-weight: 600;
  font-size: 36px;
  line-height: 42px;
  color: $white;
  margin-bottom: 44px;
  @media (max-width: $medium) {
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 0;
  }
}

// payment tabs css
.payment-tabs {
  .nav-tabs {
    border: 0;
    .nav-item {
      &:first-child {
        margin-right: 27px;
        button {
          width: 280px;
          height: 62px;
          font-size: 21px;
          background-color: #cc3333;
          color: $white;
          box-shadow: unset;
          outline: 0;
          font-weight: 500;
          border-radius: 8px;
          border: solid 1px transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          line-height: 21px;
          z-index: 1;
          @media (max-width: $medium) {
            font-size: 14px;
            line-height: 16px;
          }
          &:hover {
            background-color: #cc3333;
            border: solid 1px transparent;
          }
          &:active {
            background-color: #cc3333;
            outline: 0;
            box-shadow: none;
          }
        }
      }
      &:nth-child(2) {
        button {
          width: 280px;
          height: 62px;
          font-size: 21px;
          background-color: transparent;
          color: #cc3333;
          box-shadow: unset;
          outline: 0;
          font-weight: 500;
          border-radius: 8px;
          border: solid 1px #cc3333;
          display: flex;
          justify-content: center;
          align-items: center;
          line-height: 21px;
          &:hover,
          &:focus,
          &:active {
            box-shadow: none;
            outline: 0;
            background-color: #cc3333 !important;
            border: 1px solid #cc3333;
            color: #fff;
            padding: 0 12px;
            &:focus {
              box-shadow: none;
            }
          }
        }
      }
    }
  }
}
.payment-tabs-btn {
  display: flex;
  align-items: center;
  @media (max-width: $medium) {
    margin-top: 40px;
  }
  .payment-tabs-btn-left {
    @media (max-width: $medium) {
      padding-right: 7px;
    }
  }
  .payment-tabs-btn-right {
    @media (max-width: $medium) {
      padding-left: 8px;
    }
  }
  .ach-btn {
    width: 100%;
    height: 62px;
    margin-right: 27px;
    font-weight: 500 !important;
    @media (max-width: $medium) {
      height: 60px;
      font-size: 18px;
    }
    .ach-icon {
      margin-right: 11px;
    }
  }
  .credit-btn {
    width: 100%;
    height: 62px;
    font-weight: 500;
    @media (max-width: $medium) {
      height: 60px;
    }
    .credit-icon {
      margin-right: 11px;
    }
  }
}

.payment-lock {
  margin-top: 53px;
  filter: blur(2px);
  @media (max-width: $medium) {
    margin-top: 25px !important;
  }
  .payment-lock-wrap {
    position: relative;
    width: 100%;
    .payment-lock-pic {
      width: 100%;
      @media (max-width: $medium) {
        height: 226px;
      }
    }
    .lock-icon {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
  }
}
.payment-col-wrap {
  background-color: #21222d;
  padding: 27px;
  border-radius: 13px;
  margin-left: 76px;
  @media (max-width: $medium) {
    margin-left: 0;
    padding: 25px 20px;
  }
  .add-ons-titile {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: rgba(123, 123, 123, 0.8);
    margin-bottom: 13px;
    @media (max-width: $medium) {
      font-size: 13px;
      line-height: 15px;
      margin-bottom: 12px;
    }
  }
  .plan-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 46px 0 46px 0;
    @media (max-width: $medium) {
      margin: 30px 0;
    }
    .plan-item-left {
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: $grey;
      @media (max-width: $medium) {
        font-size: 14px;
        line-height: 16px;
      }
      @media (min-width: $small-medium) and (max-width: $medium) {
        font-size: 16px;
      }
    }
    .plan-item-right {
      font-weight: 600;
      font-size: 20px;
      line-height: 21px;
      color: $white;
      @media (max-width: $medium) {
        font-size: 14px;
        line-height: 16px;
      }
      @media (min-width: $small-medium) and (max-width: $medium) {
        font-size: 16px;
      }
    }
  }
  .add-ons {
    background-color: rgba(204, 51, 51, 0.1);
    padding: 21px 22px;
    border-radius: 9px;
    margin-bottom: 31px;
    @media (max-width: $medium) {
      padding: 20px;
    }
    .plan-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 15px 0 0 0;
      .plan-item-left {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $grey;
        @media (max-width: $medium) {
          font-size: 14px;
          line-height: 16px;
        }
      }
      .plan-item-right {
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        color: $white;
      }
    }
  }
}
.subscription-plan-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: $white;
}
.plan-divider {
  border-color: #7b7b7b;
}
.total-amount {
  margin-top: 30px;
  @media (max-width: $medium) {
    margin-top: 26px;
  }
  .total-amount-text {
    font-weight: 400;
    font-size: 20px;
    line-height: 21px;
    color: $grey;
    margin-bottom: 13px;
    text-transform: capitalize;
    @media (max-width: $medium) {
      font-size: 15px;
      line-height: 18px;
    }
    @media (min-width: $small-medium) and (max-width: $medium) {
      font-size: 17px;
    }
  }
  .total-amount-price {
    font-weight: 700;
    font-size: 36px;
    line-height: 41px;
    color: $white;
    @media (max-width: $medium) {
      font-size: 32px;
      line-height: 38px;
    }
  }
}
.amount-pay {
  margin-top: 48px;
  margin-left: 76px;
  @media (max-width: $medium) {
    margin-left: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;
    padding: 0 12px;
  }
  .amount-pay-btn {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: $white;
    height: 53px;
    @media (max-width: $medium) {
      font-size: 14px;
      height: 50px;
    }
  }
}
.payment-row {
  position: relative;
  @media (max-width: $medium) {
    flex-direction: column-reverse;
  }
  .add-infor-wrap {
    position: absolute;
    bottom: 17%;
    left: 25%;
    right: 0;
    @media (max-width: $medium) {
      bottom: 15%;
      left: 20px;
    }
    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 29px;
      color: $white;
      font-family: 'Caveat', sans-serif;
      text-transform: uppercase;
      @media (max-width: $medium) {
        font-size: 18px;
        line-height: 33px;
      }
    }
    .custom-mark {
      position: absolute;
      transform: rotate(188deg);
      margin-top: 35px;
      left: 23%;
      width: 108px;
      @media (max-width: $medium) {
        transform: rotate(230deg);
        margin-top: 26px;
        left: 15%;
        width: 60px;
      }
    }
  }
}
.radio-sec {
  display: flex;
  align-items: center;
  margin-top: 10px;
  .radio-left {
    display: flex;
    align-items: center;
    @media (max-width: $medium) {
      &:first-child {
        margin-bottom: 25px;
      }
    }
    img {
      cursor: pointer;
    }
    .radio-text {
      margin-left: 8px;
      opacity: 0.6;
      font-size: 14px;
      color: #fff;
    }
    &:first-child {
      margin-right: 74px;
    }
  }
  .form-check-input {
    background-color: transparent;
    border-color: #aaabae !important;
    border: 2px solid;
    width: 18px;
    height: 18px;
    box-shadow: none;
    cursor: pointer;
    border-radius: 50% !important;
    &:checked {
      background-color: transparent !important;
      border-color: #cc3333 !important;
      border: 2px solid;
      width: 18px;
      height: 18px;
      box-shadow: none;
      border-radius: 50% !important;
      color: #cc3333 !important;
    }
    &:checked[type='radio'] {
      background-image: url('../../icons/radio-dot.svg') !important;
      background-size: 8px;
    }
    &:focus {
      box-shadow: none !important;
    }
  }
}
.payment-ach-option {
  margin-top: 35px;
  .form-control {
    padding-left: 16px;
  }
  @media (max-width: $medium) {
    margin-top: 40px;
    .radio-sec {
      display: unset;
      &:first-child {
        margin-right: 0;
      }
    }
  }

  .remember-me-wrap {
    margin-top: 44px;
    @media (max-width: $medium) {
      .form-check-label {
        line-height: 22px;
        top: 1px;
      }
    }
  }
  .ach-payment {
    margin-top: 48px;
    width: 100%;
    .ach-payment-btn {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
    }
  }
}
.payment-ach-option-stripe {
  div {
    margin-bottom: 27px;
    label {
      margin: 0 0 5px 0 !important;
      font-size: 14px;
      color: #ffffff;
      font-weight: 500;
      line-height: 18px;
      display: block;
    }
    input {
      background-color: #21222d !important;
      border: 0 !important;
      height: 44px !important;
      border-radius: 7px !important;
      width: 100% !important;
      padding: 11px 16px 10px 16px;
      caret-color: #fff;
      color: #fff;
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #21222d inset !important;
        -webkit-text-fill-color: #fff !important;
        font-size: 14px;
      }
      &:focus {
        background-color: transparent;
        border-color: unset;
        outline: 0;
        box-shadow: none;
        color: #fff;
        border: 0;
      }
    }
  }
}

.stripe-card-number {
  background-color: #21222d;
  height: 44px !important;
  padding: 13px 16px;
  color: #fff;
  border-radius: 7px;
  &::placeholder {
    color: $grey;
    font-size: 14px;
    @media (max-width: $medium) {
      font-size: 14px;
    }
  }
  .payment-col-left {
    @media (max-width: $medium) {
      margin-bottom: 30px;
    }
  }
}
.payment-ach-wrap {
  margin-bottom: 0;
}
.payment-col-left {
  @media (max-width: $medium) {
    margin-bottom: 20px;
    margin-top: 44px;
  }
  @media (min-width: $small-medium) and (max-width: $medium) {
    width: 100%;
  }
}

.card-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #7B7B7B;
  @media (max-width: $medium) {
    font-size: 14px;
    line-height: 16px;
  }
  @media (min-width: $small-medium) and (max-width: $medium) {
    font-size: 18px;
  }
}
.add-credit-address {
  @media (max-width: $small-medium) {
    margin-bottom: 10px !important;
  }
}