* {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0px;
    text-rendering: auto;
}

ul,
ol,
li {
    list-style-type: none;
    padding: 0;
}

html,
body {
    height: 100%;
}

body {
	width: 100%;
	height: 100%;
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	line-height: 1.2;
	font-weight: 400;
	color: $theme-text;
	-webkit-tap-highlight-color: transparent !important;
}

*:before,
*:after {
    -webkit-tap-highlight-color: transparent !important;
}
#__next {
    height: 100%;
}

.btn:focus {
    outline: none;
}
body,
ul,
figure {
    padding: 0;
    margin: 0;
}
li {
    list-style: none;
}
a {
    color: $link-color;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    &:hover,
    &:focus,
    &:active {
        outline: none;
        text-decoration: none;
        cursor: pointer;
        color: darken($link-color, 10);
    }
}
p {
    font-size: 14px;
}

#root {
    height: 100%;
}
.text-danger{
	color: $danger !important;
}
.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
}
.main {
    height: 100%;
    flex: 1;
    margin-left: 0;
    position: relative;
    @extend %all-animation;
    @media (min-width: $extra-large) {
        margin-left: $sidebar-width;
    }
}
.menu-open{
	overflow: hidden;
}
.menu-close{
	overflow: visible;
}
.main-menu {
    position: fixed;
    left: -($sidebar-width);
    top: 0;
    height: 100%;
    z-index: 1005;
    background: $white;
    @extend %all-animation;
    @media (min-width: $extra-large) {
        left: 0;
				display: none;
    }
    &.active {
        left: 0;
    }
}
.menu-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba($black, 0.4);
    z-index: 1004;
    opacity: 0;
    visibility: hidden;
    @extend %all-animation;
		display: none;
    &.active {
        opacity: 1;
        visibility: visible;
				display: block;
        @media (min-width: $extra-large) {
						display: none;
            opacity: 0;
            visibility: hidden;
        }
    }
}
.close-sidebar {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 99;
    @media (min-width: $extra-large) {
        display: none;
    }
}
.header-menu {
    position: fixed;
    z-index: 1003;
    top: 25px;
    left: 25px;
    @media (min-width: $medium) {
        left: 35px;
        top: 40px;
    }
    @media (min-width: $extra-large) {
        display: none;
    }
}
.main-container {
    display: flex;
    height: 100%;
}
.inner-main-container {
    padding: 0 30px;
    padding-top: 185px;
    @media (min-width: $medium) {
        padding: 0 40px;
        padding-top: 185px;
    }
}
.disabled {
    opacity: 1 !important;
    color: rgba(204, 51, 51, 0.5) !important;
    // pointer-events: none;
}

.divider {
    width: 100%;
    height: 1px;
    background: #f0f0f0;
    margin: 0 0 40px;
}
%all-animation {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

.dropdown {
    .dropdown-toggle {
        color: $card-dropdown-icon;
        font-size: 26px;
        line-height: 10px;
        height: 50px;
        min-width: 50px;
        align-items: center;
        justify-content: center;
        border: solid 1px transparent;
        box-shadow: none;
        @extend %all-animation;
    }
    .dropdown-menu {
        border-radius: 10px;
        border: 1px solid #e9ecef;
        background: #fbfbfc;
        padding: 10px 0;
        min-width: 200px;
        .dropdown-item {
            font-size: 16px;
            padding: 10px 30px;
            color: $theme-text;
						align-items: center;
    				display: inline-flex;
            &:hover {
                background: #fbfbfc;
            }
            img {
                margin-right: 20px;
            }
        }
    }
}
.alert{
	position: fixed;
	top: 60px;
	left: 0;
	max-width: 625px;
	width: 100%;
	min-height: 60px;
  border-radius: 12px;
  border: 1px solid;
	display: flex;
  align-items: center;
	z-index: 15;
	padding: 20px;
	@media (min-width: $medium) {
		left: 25%;
		left: 50%;
		transform: translate(-50%);
	}
	@media (min-width: $extra-large) {
		left: 38%;
		transform: translate(0);
	}
	.alert-heading{
		color: #27303A;
		font-size: 14px;
		font-weight: 600;
		line-height: 140%;
		text-transform: capitalize;
		margin-bottom: 4px;
	}
	p{
		color: #2F3F53;
		font-size: 12px;
		font-weight: 400;
		line-height: 1.6;
	}
	img{
		margin-right: 16px;
	}
	&.alert-dismissible{
		padding-right: 80px;
		.btn-close{
			top: 32px;
			right: 16px;
			padding: 5px;
			background-size: 50%;
		}
	}
	&.alert-success{
		border: 1px solid #48C1B5;
		background: #F6FFF9;
	}

}
.chip {
    border-radius: 10px;
    background: #f7f7f7;
    color: #0f5d99;
    display: inline-block;
    padding: 11px 20px;
    margin-bottom: 30px;
    &.primary {
        border-radius: 25px;
        border: 1px solid #0f5d99;
        padding: 6px 10px;
        margin-bottom: 5px;
        background: #fff;
        margin-left: 2.5px;
        margin-right: 2.5px;
        &:not(:first-child) {
            margin-left: 2.5px;
        }
    }
    &:not(:first-child) {
        margin-left: 20px;
    }
    .close {
        display: inline-block;
        margin-left: 15px;
        cursor: pointer;
    }
}
.back-with-text{
    display: flex;
    align-items: center;
    margin: 20px 0;
    @media (min-width: $medium) {
        margin-top: 0;
    }
    span{
        display: inline-block;
        font-size: 24px;
        font-weight: 600;
        line-height: 30px;
        margin-left: 20px;
    }
}

.header-tank-representaion {
    justify-content: space-between;
    .btn-close {
        font-size: 21px;
        padding: 0;
        box-shadow: none;
        background: transparent;
        cursor: pointer;
        opacity: 1;
        &:hover,
        &:focus {
          box-shadow: none;
        }
      }
 }
.multi-select-wrap .react-tagsinput-tag {
    text-transform: none !important;
}