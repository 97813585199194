
header{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  width: 100%;
  background: linear-gradient(180deg, #DFF6FF 0%, rgba(223, 246, 255, 0.00) 100%), #FFF;
  height: 80px;
  padding: 0 30px;
  margin-bottom: 24px;
  @media (min-width: $medium) {
    z-index: 1002;
    padding: 0 40px;
		height: 146px;
	}
  .container-fluid {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
    height: 100%;
  }
  .menu{
    display: flex;
    align-items: center;
    .nav-link{
      margin-right: 30px;
    }
  }
  .user-dropdown{
    display: flex;
    align-items: center;
    .profile-pic{
      height: 32px;
      width: 32px;
      margin-right: 20px;
    }
    .profile-menu-btn{
      cursor: pointer;
      position: relative;
      z-index: 103;
    }
  }
}

.back-page-wrap {
  display: flex;
  align-items: center;
  cursor: pointer;
  .left-arrow {
    margin-right: 4px;
  }
  .back-title-heading {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
  }
}
.reset-password-container {
  margin-bottom: 40px;
  .reset-close {
    width: 21px;
    height: 21px;
    margin-right: 4px;
  }
}
.space-for-back {
  margin-bottom: 50px;
  @media (max-width: $small-medium) { 
    margin-bottom: 0;
    position: absolute;
    top: 35px;
    right: 20px;
  }
}
.back-subtitle {
  line-height: 29px !important;
}
.back-boarding-title {
  margin-bottom: 37px;
}