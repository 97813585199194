.flex-table-column-5 {
  width: 5%;
}

.flex-table-column-6 {
  width: 6%;
}

.flex-table-column-7 {
  width: 7%;
}

.flex-table-column-8 {
  width: 8%;
}

.flex-table-column-9 {
  width: 9%;
}

.flex-table-column-10 {
  width: 10%;
}

.flex-table-column-11 {
  width: 11%;
}

.flex-table-column-12 {
  width: 12%;
}

.flex-table-column-13 {
  width: 13%;
}

.flex-table-column-14 {
  width: 14%;
}

.flex-table-column-15 {
  width: 15%;
}

.flex-table-column-16 {
  width: 16%;
}

.flex-table-column-17 {
  width: 17%;
}

.flex-table-column-18 {
  width: 18%;
}

.flex-table-column-20 {
  width: 20%;
}

.flex-table-column-25 {
  width: 25%;
}

.flex-table-column-30 {
  width: 30%;
}

.flex-table-column-35 {
  width: 35%;
}

.flex-table-column-40 {
  width: 40%;
}

.flex-table-column-45 {
  width: 45%;
}

.flex-table-column-50 {
  width: 50%;
}

.flex-table-column-55 {
  width: 55%;
}

.flex-table-column-60 {
  width: 60%;
}

.txt-center {
  text-align: center;
}

.show-all-container {
  margin: 40px 0;
  .show-all-padding {
    width: auto;
    padding: 10px 52px 0 53px;
    height: 40px;
  }
}
.ml-20 {
  margin-left: 20px;
}
.text-capitalize {
  text-transform: capitalize;
}
.table-wrapper {
  // table optimzised
  @media (max-width: $medium) {
    .thead {
      display: none;
    }
    .td {
      &::before {
        content: attr(data-title);
        display: block;
        font-size: 14px;
        font-weight: 500;
        color: #aaabae;
        margin-bottom: 10px;
        line-height: 18px;
      }
      &:nth-child(1) {
        width: 100%;
      }
      &:nth-child(2) {
        width: 50%;
      }
      &:nth-child(3) {
        width: 50%;
      }
      &:nth-child(4) {
        width: 50%;
      }
      &:nth-child(5) {
        width: 50%;
      }
      &:nth-child(6) {
        width: 50%;
      }
      @media (min-width: $small-medium) and (max-width: $medium) {
        &:nth-child(1) {
          width: 50%;
        }
        &:nth-child(2) {
          width: 50%;
        }
        &:nth-child(3) {
          width: 50%;
        }
      }
      &:last-child {
        // display: none;
        margin-bottom: 0 !important;
        .td {
          content: attr(data-title);
          display: none;
        }
      }
    }
    .td[data-title='Action'] {
      // display: none;
    }
  }
  .thead {
    .tr {
      height: 58px;
      padding: 0 27px;
      border-radius: 7px;
      background-color: rgba(204, 51, 51, 0.1);
      display: flex;
      align-items: center;
      .th {
        padding: 0 10px;
      }
    }
  }
  .tbody {
    .tr {
      height: 71px;
      padding: 0 27px;
      border-radius: 7px;
      background-color: #21222d;
      margin-bottom: 13px;
      display: flex;
      align-items: center;
      -webkit-flex-wrap: wrap;
      cursor: pointer;
      @media (max-width: $medium) {
        height: auto;
        padding: 20px 20px 20px 20px;
        position: relative;
        margin: 0 0 20px 0;
      }
      &:hover {
        background-color: rgb(255 255 255 / 9%);
      }
      .td {
        @media (max-width: $medium) {
          padding: 0;
          margin-bottom: 30px;
          display: flex;
          flex-direction: column;
          align-self: baseline;
        }
        .table-txt {
          opacity: 0.7;
          color: #fff;
          font-size: 12px;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 0;
          background-color: transparent;
          border: 0;
          word-break: break-word;
          &::after {
            display: none;
          }
          .action-icon {
            margin-left: 6px;
          }
        }
        .store-pic {
          padding-right: 15px;
          width: 40px;
          height: 35px;
        }
      }
      .listing-title {
        font-size: 14px;
        line-height: 17px;
        color: #fff;
        font-weight: 600;
        margin-bottom: 0;
        // .text-name {
        //   font-size: 12px;
        //   line-height: 21px;
        //   &.verify {
        //     color: #43ab47;
        //   }
        //   &.notverify {
        //     color: #c33;
        //   }
        // }
      }
      .text-email {
        font-size: 14px;
        line-height: 21px;
        color: #aaabae;
        font-weight: 400;
        width: 100%;
      }
      .listing-normal {
        font-size: 14px;
        line-height: 17px;
        color: #fff;
        font-weight: 400;
        .dot-icon {
          cursor: pointer;
        }
      }
    }
  }
  .table-heading {
    font-size: 12px;
    color: rgba(170, 171, 174, 0.7);
    // opacity: 0.7;
    text-transform: uppercase;
    .icon-filter {
      margin-left: 4px;
      cursor: pointer;
    }
    .icon-filter-custom {
      margin-left: 4px;
      cursor: pointer;
      position: relative;
      top: -1px;
    }
  }
}
.shorting-colum {
  font-size: 8px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: #000;
  text-align: center;
  margin: -2px auto 0 5px;
  .icon-shorting {
    display: flex;
    flex-direction: column;
    a {
      border-top: 0.5em solid #000;
      border-right: 0.5em solid transparent;
      border-bottom: 0;
      border-left: 0.5em solid transparent;
      margin: 1px 0 1px;
      &.up {
        transform: rotate(180deg);
      }
    }
  }
}
.shorting-colum:hover,
.shorting-colum:focus {
  color: #000;
}
.list-container {
  .tr {
    margin-bottom: 10px;
  }
  .table-txt {
    font-size: 13px;
  }
  .table-heading {
    font-weight: 500;
  }
}

.admin-common-body {
  margin: 36px 36px 89px 36px;
  min-height: calc(100vh - 269px);
  @media (max-width: $medium) {
    margin: 40px 20px 89px 20px;
    height: auto;
  }
}
.service-list-container {
  margin-top: 45px;
  padding: 0 12px;
  @media (max-width: $medium) {
    margin-top: 30px;
    padding: 0;
  }
}
.admin-header-title {
  font-weight: 700;
  font-size: 23px;
  line-height: 27px;
  color: #fff;
  margin-bottom: 44px;
  @media (max-width: $medium) {
    margin-bottom: 30px;
    font-size: 20px;
  }
}
.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: $medium) {
    display: unset;
  }
  @media (min-width: $small-medium) and (max-width: $medium) {
    display: flex;
  }
  .header-right {
    .header-primary-btn {
      min-width: 145px;
      width: auto;
      height: 44px;
      font-size: 14px;
      line-height: 16px;
      font-weight: 600;
      @media (max-width: $medium) {
        width: 100%;
      }
    }
  }
}
.header-right {
  display: flex;
  .header-outline-btn {
    margin-right: 18px;
    width: 145px;
    height: 44px;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
  }
  .header-primary-btn {
    width: auto;
    height: 44px;
    font-size: 14px;
    line-height: 16px;
  }
}

.first-column-wrapper {
  display: flex;
  align-items: center;
  .tank-pic {
    margin-right: 12px;
  }
  &.dir-column {
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
  }
}
.icon-three-dot {
  font-size: 14px;
  color: #848484;
  background-color: transparent;
  border-radius: 3px;
  padding: 8px;
  border: 0;
  text-transform: uppercase;
  height: 40px;
  margin-left: 0;
  display: flex;
  justify-content: center;
  @media (max-width: $medium) {
    position: absolute;
    top: 23px;
    right: 15px;
    height: 20px;
    width: 20px;
  }
  .dropdown-toggle {
    position: relative;
    background-color: transparent;
    @media (max-width: $medium) {
      height: 26px;
      width: 20px;
    }
    &:hover,
    &:focus {
      background-color: transparent !important;
    }
    &::after {
      content: url('../../icons/dot.svg');
      width: 40px;
      height: 40px;
      border: 0;
    }
  }
  .dropdown-menu {
    box-shadow: none;
    border: 0;
    background-color: #303039;
    min-width: 180px;
    padding: 0;
    border-radius: 7px;
    right: 21px !important;
    // transform: translate(-7.77778px, 50px) !important;
    // @media (max-width: $medium) {
    //   right: 0 !important;
    // }
    .dropdown-item {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      height: 45px;
      padding: 0 13px;
      font-family: 'Roboto', sans-serif;
      border-bottom: 1px solid #4a4b50;
      text-transform: capitalize;
      @media (max-width: $medium) {
        height: 50px;
        padding: 0 15px;
      }
      &:hover {
        color: #cc3333;
        background-color: transparent;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
// table optimzised
@media (max-width: $medium) {
  .table-wrapper {
    .thead {
      display: none;
    }
  }
  .td {
    &::before {
      content: attr(data-title);
      display: block;
      font-size: 11px;
      font-weight: 500;
      color: #6b7280;
    }
  }
}
.header-left {
  @media (max-width: $medium) {
    margin-bottom: 20px;
  }
  @media (min-width: $small-medium) and (max-width: $medium) {
    margin-bottom: 0;
  }
}
.offcanvas-start {
  @media (max-width: $medium) {
    border: 0 !important;
  }
}
.btn-status {
  height: 30px;
  width: 100px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  padding: 9px 5px 7px;
  &.active {
    background: #d4e2d5;
    color: #43a047;
  }
  &.deactive {
    background: #fcd4d3;
    color: #c33;
  }
}
.after-edit-profile {
  @media (max-width: $small-medium) {
    position: absolute;
    bottom: -83px;
    left: 0;
    right: 0;
    display: unset;
  }
  @media (min-width: $small-medium) and (max-width: $medium) {
    position: absolute;
    bottom: -83px;
    right: 0;
    left: unset;
    display: flex;
  }
  .header-outline-btn {
    @media (max-width: $medium) {
      width: 100%;
      margin-bottom: 20px;
    }
    @media (min-width: $small-medium) and (max-width: $medium) {
      width: auto;
    }
  }
  .header-primary-btn {
    @media (min-width: $small-medium) and (max-width: $medium) {
      width: auto !important;
    }
  }
}
.below-filter {
  .service-list-container {
    margin-top: 0;
  }
}
.text-name {
  &.verify {
    color: #43ab47;
  }
  &.notverify {
    color: #c33;
  }
}
.three-dot-align {
  .dropdown-menu {
    right: 33px !important;
    @media (max-width: $medium) {
      right: 21px !important;
    }
  }
}

@media (max-width: $small-medium) {
  .transaction-history-mobile {
    .tr {
      .td {
        &:nth-child(2) {
          padding-right: 10px;
        }
      }
    }
  }
}