.main-admin {
  height: 100vh;
}
.admin-sidebar {
  background-color: #21222d;
  padding: 0 18px 0 18px;
  min-height: 100vh;
  max-width: 218px;
  width: 100%;
  position: relative;
  .sidebar-logo {
    text-align: center;
    margin: 30px 0 45px 0;
    @media (min-width: 1200px) and (max-width: 1340px) {
      margin: 25px 0 35px 0;
    }
    img {
      width: 151px;
      cursor: pointer;
    }
  }
  @media (max-width: 991px){
    display: none;
  }
}
.admin-right {
  padding: 0 !important;
}
.sidebar-bottom {
  background-image: url('../../img/unlock-sidebar.png');
  background-color: #0f101a;
  width: 182px;
  height: 187px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 18px;
  padding: 18px;
  position: absolute;
  bottom: 18px;
  .sidebar-bottom-tag {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: rgba(170, 171, 174, 0.8);
    text-transform: uppercase;
  }
  .sidebar-bottom-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: $white;
    margin-top: 8px;
    text-transform: uppercase;
  }
}
.sidebar-container {
  .main-navigation {
    font-size: 13px;
    color: #aaabae;
    line-height: 25px;
    margin-bottom: 22px;
    text-transform: capitalize;
  }
  .nav {
    list-style: none;
    padding-left: 0;
    // overflow-y: scroll;
    // height: 200px;
    // margin-right: -18px;
    // &::-webkit-scrollbar {
    //   opacity: 0;
    //   visibility: hidden;
    //   @media (max-width: $medium) {
    //     display: none;
    //   }
    // }
    // &::-webkit-scrollbar-thumb {
    //   opacity: 0;
    //   visibility: hidden;
    // }
    // &::-webkit-scrollbar-thumb:vertical {
    //   opacity: 0;
    //   visibility: hidden;
    // }
    // @media (max-width: $medium) {
    //   height: auto;
    // }
    .nav-item {
      margin: 0 0 16px 0;
      width: 100%;
      @media (min-width: 1200px) and (max-width: 1320px) {
        margin: 0 0 14px 0;
      }
      a {
        &.nav-link {
          font-size: 16px;
          color: #aaabae;
          width: 100%;
          line-height: 25px;
          height: 44px;
          display: flex;
          align-items: center;
          text-transform: capitalize;
          padding: 0;
          font-weight: 400;
          border-radius: 7px;
          @media (min-width: 1200px) and (max-width: 1320px) {
            font-size: 14px;
          }
          cursor: pointer;
          span {
            font-size: 18px;
          }
        }
      }
      span {
        padding: 0 12px;
      }
    }
  }
}
.sidebar-container .nav .nav-item a.nav-link:hover,
.sidebar-container .nav .nav-item a.nav-link.active {
  background-color: #cc3333;
  color: #fff !important;
  border-radius: 7px;
  .icon-tank {
    &::before {
      content: '\e915';
      color: #fff;
    }
  }
  .icon-dashboard {
    &::before {
      content: '\e909';
      color: #fff;
    }
  }
  span {
    svg {
      path {
        fill: $white;
      }
    }
  }
}
// .sidebar-container .nav .nav-item a.nav-link:hover span::before, .sidebar-container .nav .nav-item a.nav-link.active span::before {
// 	color: #fff;
// }
.right-sidebar {
  padding: 30px !important;
  background-color: #000;
}
.search-sec {
  .search-group {
    @media (max-width: 576px) {
      padding: 0;
      margin: 0;
      width: 100%;
    }
    &.user-search-admin {
      input {
        padding: 13px 15px 15px 9px;
        width: 385px;
        @media (max-width: 576px) {
          width: 332px;
        }
      }
    }
    .search-icon {
      border: 0;
      height: 44px;
      border-radius: 7px;
      border-right: 0;
      padding: 13px 1px 13px 13px;
      background-color: #21222d;
    }
  }
  .search-input {
    border-left: 0;
    border-right: 0;
    padding: 13px 10px 13px 9px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    width: 385px;
    @media (max-width: 991px) {
      height: 44px;
      font-size: 12px;
      padding: 0 0 0 10px;
      width: 271px;
    }

    &:focus {
      border: 0;
      color: #fff;
      border-left: 0;
    }
    &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
      color: $grey;
    }
    &::-moz-placeholder { /* Mozilla Firefox 19+ */
      color: $grey;
      opacity:  1;
    }
    &::-ms-input-placeholder { /* Microsoft Edge */
      color: $grey;
    }
    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      content: url('../../icons/x-circle.svg');
      cursor: pointer;
      width: 16px;
      padding-right: 0;
      @media (max-width: 991px) {
        padding-right: 10px;
      }
      @media (min-width: $small-medium) and (max-width: $medium) {
        padding-right: 10px;
      }
    }
  }
}

.admin-dashboard-sidebar {
  width: 218px;
  // @media (min-width: $extra-extra-large) {
  //   max-width: unset;
  //   width: 16.66666667%;
  // }
}
.admin-dashboard-right {
  width: calc(100% - 218px);
  padding: 0;
  @media (max-width: 991px){
    width: 100%;
  }
  // max-width: unset;
  // width: 81.66666667%;
  // @media (min-width: $extra-extra-large) {
  //   max-width: unset;
  //   width: 82.66666667%;
  // }
}
.probes-search-width {
  width: 240px !important;
}

// graph scss
.temp-sensor-wrapper {
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 44px;
  @media (max-width: $small-medium) {
    display: unset;
  }
  .switch-main {
    @media (max-width: $medium) {
      position: unset;
    }
    @media (max-width: $small-medium) {
      margin: 20px 0 !important;
      position: unset;
    }
  }
  .dashboard-export-dropdown {
    @media (max-width: $small-medium) {
      margin: 20px 0 !important;
    }
  }
}
.temp-sensor {
  background-color: #21222D;
  padding: 27px;
  border-radius: 7px;
  @media (max-width: $medium) {
    padding: 20px;
  }
  .temp-sensor-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: $white;
    margin-top: 8px;
    margin-bottom: 44px;
    @media (max-width: $small-medium) {
      margin-bottom: 30px;
    }
  }
  .temp-sensor-wrap {
    margin: 0 0 34px 0;
  }
  .remember-me-wrap {
    justify-content: unset;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    @media (max-width: $small-medium) {
      display: unset;
    }
  }
  .form-check {
    margin: 0 44px 27px 0;
    &:last-child {
      margin-right: 0;
    }
    .form-check-input {
      background-color: transparent;
      border-color: $grey !important;
      border: 1px solid;
      width: 19px;
      height: 19px;
      border-radius: 3px !important;
      box-shadow: none;
      cursor: pointer;
      &:checked {
        background-color: transparent !important;
        border-color: #A8C5DA !important;
        border: 1px solid;
      }
      &:checked[type='checkbox'] {
        background-image: url('../../icons/checkbox-sqaure.svg') !important;
        background-size: auto;
      }
      &:focus {
        box-shadow: none !important;
      }
    }
    .form-check-label {
      color: #fff;
      font-size: 15px;
      font-weight: 400;
      line-height: 17px;
      margin-left: 9px;
      position: relative;
      top: 6px;
      @media (max-width: $medium) {
        font-size: 13px;
        line-height: 15px;
        top: 5px;
      }
    }
  }
  .recharts-responsive-container {
    margin-left: -29px !important;
    .recharts-brush {
      .recharts-brush-slide {
        fill: #fff !important;
        fill-opacity: 1 !important;
      }
      rect {
        &:first-child {
          fill: green !important;
        }
      }
    }
    .recharts-brush-traveller {
      rect {
        &:first-child {
          fill: #4f505c !important;
        }
      }
    }
    .recharts-brush-texts {
      text {
        fill: #fff !important;
      }
    }
    tspan {
      font-size: 12.444px !important;
      line-height: 23px !important;
    }
  }
  .recharts-surface {
    rect {
      &:first-child {
        fill: blue !important;
      }
    }
    .recharts-brush-slide {
      fill: #cc3333 !important;
      fill-opacity: 1 !important;
    }
  }
}

.below-graph {
  // gap: 30px;
  // padding: 11px;
  // margin-top: 30px;
  // @media (min-width: $small-medium) and (max-width: $medium) {
  //   gap: 20px;
  // }
  // @media (min-width: $medium) and (max-width: 1100px) {
  //   gap: 20px;
  // }
  .below-graph-title {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 13px;
    text-align: left;
    color: #7B7B7B;
    display: flex;
    align-items: center;
    @media (max-width: $small-medium) {
      font-size: 11px;
    }
    @media (min-width: $small-medium) and (max-width: $medium) {
      font-size: 12px;
    }
    .error-icon {
      margin-left: 12px;
    }
  }
  .below-graph-left {
    padding: 0;
    text-align: center;
    // width: 48.5%;
    // height: 440px;
    @media (max-width: $small-medium) {
      width: 100%;
      padding: 20px;
    }
    img {
      width: 120px;
    }
  }
  .below-graph-right {
    border-radius: 10px;
    padding: 13px;
    background: #303039;
    width: 184px;
    height: 142px;
    // width: 48.5%;
    // height: 440px;
    overflow-y: auto;
    @media (max-width: $small-medium) {
      width: 100%;
      padding: 20px;
    }
    .below-graph-temp-wrapper {
      .below-graph-temp-item {
        margin-bottom: 11px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:last-child {
          margin-bottom: 0;
        }
        @media (max-width: $small-medium) {
          margin-bottom: 10px;
        }
        @media (min-width: $small-medium) and (max-width: $medium) {
          margin-bottom: 12px;
        }
        .below-graph-text {
          color: #AAABAE;
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
        .below-graph-number {
          color: #17812C;
          text-align: right;
          font-size: 11px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          &.darkest-red {
            color:#FD181C;
          }
          &.orange {
            color:#FD5F20;
          }
          &.tinted-yellow {
            color:#FEC662;
          }
          &.yellow {
            color:#FFFF2E;
          }
          &.light-green {
            color:#C6FF93;
          }
          &.dark-green {
            color:#13A649;
          }
          &.darkest-green {
            color:#0C7410;
          }
        }
      }
    }
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      @media (max-width: $medium) {
        display: none;
      }
    }
  }
}
.onhover-graph-respresenation {
  display: flex;
  align-items: center;
}
.temp-sensor-checkbox-colored {
  .temperature_0 {
    .form-check-input {
      &:checked {
        border-color: #A8C5DA !important;
      }
      &:checked[type='checkbox'] {
        background-image: url('../../icons/checkbox-sqaure.svg') !important;
      }
    }
  }
  .temperature_1 {
    .form-check-input {
      &:checked {
        border-color: #C6C7F8 !important;
      }
      &:checked[type='checkbox'] {
        background-image: url('../../icons/temp1.svg') !important;
      }
    }
  }
  .temperature_2 {
    .form-check-input {
      &:checked {
        border-color: #F8E7C6 !important;
      }
      &:checked[type='checkbox'] {
        background-image: url('../../icons/temp2.svg') !important;
      }
    }
  }
}
.switch-on-graph {
  display: flex;
  @media (max-width: $small-medium) {
    display: table;
    margin-bottom: 15px !important;
  }
  .switch-main {
    &:first-child {
      margin-right: 20px;
    }
  }
}