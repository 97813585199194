.btn {
  &:focus,
  &:active {
    outline: 0;
    box-shadow: none;
  }
  &.primary {
    height: 50px;
    font-size: 16px;
    background-color: #cc3333;
    color: $white;
    outline: 0;
    font-weight: 600;
    border-radius: 7px;
    border: solid 1px transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 19px;
    z-index: 1;
    box-shadow: 0px 7.111111164093018px 14.222222328186035px 0px rgba(204, 51, 51, 0.25);
    @media (max-width: $medium) {
      font-size: 14px;
      line-height: 16px;
    }
    &:hover {
      background-color: #b42222;
      border: solid 1px transparent;
    }
    &:active {
      background-color: #b42222;
      outline: 0;
      box-shadow: none;
    }
  }
  &.secondary:hover,
  &.secondary {
    background: #fff;
    color: #fff;
  }
}

.boarding {
  height: 36px;
  padding: 0 15px 0 15px;
  border-radius: 3px;
  border: solid 1px #fff;
  background-color: rgba(255, 255, 255, 0);
  color: #fff !important;
  text-decoration: none;
  align-items: center;
  display: flex;
  &.sign-up {
    &:hover {
      background-color: transparent;
      border: solid 1px #fff;
      color: #fff !important;
    }
    &:active {
      background-color: transparent;
      outline: 0;
      box-shadow: none;
      color: #fff !important;
    }
  }
}
// button for card only
.btn-primary {
  font-size: 14px;
  font-weight: 500px;
  color: #fff;
  background-color: transparent;
  border-radius: 3px;
  padding: 0 16px;
  border: 1px solid rgba(216, 216, 216, 0.3);
  text-transform: uppercase;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  @media (max-width: 1200px) {
    font-size: 12px;
    font-weight: 500px;
  }
  &.active {
    box-shadow: none;
    outline: 0;
    background-color: #ff5c39;
    color: #fff;
    border: transparent;
    border-radius: 3px;
  }
  &:hover,
  &:active {
    box-shadow: none;
    outline: 0;
    background-color: #bd2a2a !important;
    color: #fff;
    border: transparent;
    &:focus {
      box-shadow: none;
    }
  }
  &:focus {
    box-shadow: none;
    outline: 0;
    color: #fff;
    &:focus {
      box-shadow: none;
    }
  }
  &.disabled,
  &:disabled {
    opacity: 1;
    color: rgba(255, 255, 255, 0.4);
    img {
      opacity: 0.4; 
    }
  }
}
.btn-outline-border {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background-color: transparent;
  border-radius: 8px;
  padding: 0 16px;
  border: 1px solid #cc3333;
  text-transform: uppercase;
  height: 50px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
    outline: 0;
    background-color: #cc3333 !important;
    border: 1px solid #cc3333;
    color: #fff;
    padding: 0 12px;
    &:focus {
      box-shadow: none;
    }
  }
  &:disabled {
    opacity: 0.5;
    background-color: transparent;
    color: #cc3333;
    border: 1px solid #cc3333;
  }
}

.two-step-wrap {
  margin-bottom: 33px;
  margin-left: 35px;
  height: 75px;
  position: relative;
  top: 18px;
  &::before {
    content: "User Details";
    width: 100px;
    position: absolute;
    top: 39px;
    left: -35px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    color: #fff;
  }
  &::after {
    content: "Winery Details";
    width: 103px;
    position: absolute;
    top: 39px;
    left: 110px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    color: #fff;
  }
  @media (max-width: $medium) {
    margin-bottom: 40px;
  }
  .RSPBprogressBar {
    height: 9px;
    border-radius: 10px;
    position: relative;
    background-color: #21222d;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 0;
    width: 150px;
    border: 1px solid #cc3333;
    &::before {
      // content: '';
      // height: 7px;
      // width: 109px;
      // position: absolute;
      // left: 19px;
      // background-color: #21222d;
      // z-index: 1;
    }
    .RSPBstep {
      border: 1px solid #cc3333;
      border-radius: 50%;
      padding: 4px;
      background-color: #21222d;
      // position: relative;
      &>.RSPBprogression{
        background: #ff5c39;
      }
      &:first-child {
        &>.RSPBprogression{
          background: #303039;
        }
        .indexedStep {
          // &::before {
          //   content: 'User Details';
          //   width: 100px;
          //   position: absolute;
          //   top: 53px;
          //   left: -15px;
          //   font-size: 16px;
          //   line-height: 19px;
          //   font-weight: 500;
          // }
        }
      }
      &:nth-child(2) {
        background-color: transparent;
        border:0px;
        border-radius: 0px;
        padding: 0px;
        position: relative;
        &::before{
          content: '';
          border: 1px solid #cc3333;
          border-radius: 50%;
          padding: 4px;
          background-color: #21222d;
          width: 44px;
          height: 44px;
          position: absolute;
          z-index: -1;
        }
        .indexedStep {
          // &::after {
          //   content: "Winery Details";
          //   width: 103px;
          //   position: absolute;
          //   top: 53px;
          //   left: -22px;
          //   font-size: 16px;
          //   line-height: 19px;
          //   font-weight: 500;
          // }
        }
          .null{
            position: relative;
            &:after{
            content: '';
            background-color: #21222d;
            width: 110px;
            height: 7px;
            position: absolute;
            top: 13px;
            z-index: 1;
            border-radius: 0px;
            left:-111px;
            }
          }
        .accomplished{
          position: absolute;
          width: 120px;
          background-color: transparent;
          z-index: 2;
          &::after{
            content: "";
            position: absolute;
            width: 114px;
            height: 7px;
            background-color: #cc3333;
            top: 13px;
            z-index: 99999;
            left: -71px;
            border-top: 2px solid #21222d;
            border-bottom: 2px solid #21222d;
          }
          &:before{
            content: "";
            position: absolute;
            width: 34px;
            height: 34px;
            background-color: #cc3333;
            top: 0px;
            z-index: -1;
            left: 43px;
            border-radius: 50%;
            color: #fff;
          }
        }
        div {
          // &::before {
          //   content: 'Winery Details';
          //   width: 103px;
          //   position: absolute;
          //   top: 53px;
          //   left: -22px;
          //   font-size: 16px;
          //   line-height: 19px;
          //   font-weight: 500;
          // }
        }
      }
      ~ div:last-child {
        background-color: transparent !important;
      }
    }
    .RSPBprogression {
      max-width: 114px;
      width: 100% !important;
      z-index: 9;
      left: 17px;
      top: 2px;
      height: 3px;
      border-radius: 0;
      display: none;
    }
  }
  .indexedStep {
    color: #c9c9c9;
    width: 34px;
    height: 34px;
    font-size: 16px;
    background-color: #303039;
    border-radius: 50%;
    /* border-style: solid; */
    /* border-width: 1px; */
    /* border-color: rgb(206, 206, 206); */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
    line-height: 19px;
  }

  .indexedStep.accomplished {
    background-color: #cc3333;
    color: white;
    border-style: none;
  }
}
.input-disabled {
  pointer-events: none;
  opacity: 1 !important;
  color: rgba(255, 255, 255, 0.4) !important;
}