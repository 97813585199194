// contact us modal
.common-modal {
  background-color: rgba(0, 0, 0, 0.7) !important;
  @media (max-width: 576px) {
    padding: 0 15px !important;
  }
  .logout-icon {
    cursor: pointer;
    width: 36px;
  }
  // form {
  //   .form-label {
  //     font-size: 14px;
  //     line-height: 18px;
  //     @media (max-width: $medium) {
  //       line-height: 20px;
  //     }
  //   }
  // }
  .modal-dialog {
    max-width: 480px;
    width: 100%;
    @media (max-width: 576px) {
      margin: 0;
    }
  }
  .modal-id {
    color: white;
  }
  .download {
    color: red;
  }
  input[type='file'] {
    -webkit-appearance: none;
    text-align: left;
    -webkit-rtl-ordering: left;
  }
  input[type='file']::-webkit-file-upload-button {
    -webkit-appearance: none;
    float: right;
    margin: 0 0 0 10px;
    border: 1px solid #aaaaaa;
    border-radius: 4px;
    background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(#d2d0d0),
      to(#f0f0f0)
    );
    background-image: -moz-linear-gradient(90deg, #d2d0d0 0%, #f0f0f0 100%);
  }
  .modal-content {
    background-color: #21222d;
    border-radius: 18px;
    .modal-header {
      background-color: transparent;
      padding: 27px 27px 0 27px;
      border: 0;
      @media (max-width: 576px) {
        padding: 20px 20px 0 20px;
      }
      .modal-title {
        color: #fff;
        font-size: 20px;
        line-height: 23px;
        font-weight: 700;
        @media (max-width: 576px) {
          font-size: 18px;
        }
      }
      .btn-close {
        font-size: 21px;
        padding: 0;
        box-shadow: none;
        background: transparent;
        cursor: pointer;
        opacity: 1;
        &:hover,
        &:focus {
          box-shadow: none;
        }
      }
    }
    .modal-body {
      padding: 0;
      .modal-body-form {
        padding: 45px 27px 22px 27px;
        @media (max-width: 576px) {
          padding: 30px 20px 30px 20px !important;
        }
        @media (min-width: $small-medium) and (max-width: $medium) {
          padding: 45px 27px 27px 27px;
        }
      }
      .form-control {
        height: 44px;
        margin: 0;
        padding: 11px 32px 10px 16px;
        border-radius: 7px;
        border: 0;
        background-color: #303039;
        color: rgb(255, 255, 255);
        font-size: 14px;
        font-weight: 400;
        box-shadow: none;
        transition: none !important;
        line-height: 22px;
        @media (max-width: $medium) {
          font-size: 14px;
        }
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px #303039 inset !important;
          -webkit-text-fill-color: #fff !important;
          font-size: 14px;
        }
        &:hover,
        &:focus {
          -webkit-box-shadow: 0 0 0 30px #303039 inset !important;
          color: $white !important;
          font-size: 14px;
          background-color: #303039;
          border: 0;
          @media (max-width: $medium) {
            font-size: 14px;
          }
        }
        &::placeholder {
          color: $grey;
          font-size: 14px;
          @media (max-width: $medium) {
            font-size: 14px;
          }
        }
      }
      textarea {
        height: auto !important;
        background-color: #303039;
        resize: none;
        overflow: hidden;
        &:hover,
        &:focus {
          background-color: #303039;
          border-radius: 7px;
        }
      }
      .footer-modal {
        text-align: -webkit-right;
        padding: 22px 27px;
        border-top: 1px solid #2c2d33;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-color: rgba(44, 45, 51, 0.2);
        .modal-btn-submit {
          width: 178px;
          height: 44px;
          font-size: 14px;
          line-height: 17px;
          font-weight: 600;
          margin: 0;
          @media (max-width: $medium) {
            width: 147px !important;
            margin: 0;
          }
        }
      }
    }
    .modal-footer {
      display: none;
    }
  }
  // react select css
  .wrap-select {
    position: relative;
    .select__menu {
      font-size: 16px;
      padding: 0;
      border-radius: 7px;
      background-color: #393a42;
      width: 100% !important;
      z-index: 2;
      overflow: hidden;
      margin: 3px 0 0 0;
      .select__menu-list {
        padding: 0;
        margin-right: 0;
        .select__option {
          font-size: 15px;
          background-color: unset;
          color: $white;
          font-weight: 400;
          line-height: 35px;
          cursor: pointer;
          padding: 8px 15px 7px 15px;
          border-bottom: 1px solid #4a4b50;
          &:last-child {
            border-bottom: 0;
          }
          &:hover {
            background-color: #4a4b50 !important;
            color: #fff;
          }
        }
      }
    }
    .select__input-container {
      color: #fff;
    }
    .on-input-icon {
      position: absolute;
      top: 50%;
      right: 5px;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
    .select__control {
      background-color: #303039;
      border-color: #303039;
      border-radius: 7px;
      font-size: 14px;
      border-style: solid;
      border-width: 1px;
      cursor: pointer;
      height: 44px;
      box-shadow: none !important;
      &:hover,
      &:focus {
        border-color: #303039 !important;
        box-shadow: unset !important;
      }
      svg {
        fill: #9d9d9d;
      }
    }
    .select__indicators {
      .select__indicator-separator {
        display: none;
      }
    }
    .css-1s2u09g-control {
      background-color: #21222d;
      border-color: #353535;
      border-radius: 3px;
      font-size: 14px;
      border-style: solid;
      border-width: 1px;
      cursor: pointer;
      height: 44px;
      &:hover {
        border-color: #353535;
      }
    }
    .css-1pahdxg-control {
      background-color: transparent;
      box-shadow: none;
      border-color: #353535;
      height: 44px;
      font-size: 14px;
      &:hover {
        border-color: #353535 !important;
        box-shadow: none;
      }
      &:focus-within {
        border-color: 0;
        box-shadow: none;
        .css-qc6sy-singleValue {
          font-size: 14px !important;
        }
      }
    }
    .css-qc6sy-singleValue {
      color: #fff;
    }
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
    .css-tlfecz-indicatorContainer {
      svg {
        fill: #9d9d9d;
        // width: 14px;
      }
    }
    .form-control {
      option {
        color: #000;
      }
    }
    .css-pndypt-Input[data-value] {
      color: #fff !important;
    }
    .select__value-container {
      padding-left: 16px;
      .select__single-value {
        color: #fff !important;
      }
      .select__placeholder {
        margin: 0;
      }
    }
    .select__placeholder {
      color: $grey;
      font-size: 14px;
      font-weight: 400;
    }
  }
  //end react select css

  // credit modal css
  .payment-ach-option-stripe {
    div {
      input {
        background-color: #303039 !important;
        font-size: 14px;
        &::placeholder {
          font-size: 14px;
        }
      }
    }
    .stripe-card-number {
      background-color: #303039;
    }
  }
  .modal-body-form {
    padding: 45px 27px 22px 27px;
  }
}
.add-credit-modal {
  .footer-modal {
    text-align: -webkit-right;
    padding: 22px 27px;
    border-top: 1px solid #2c2d33;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: rgba(44, 45, 51, 0.2);
    .ach-payment-btn {
      width: 178px !important;
    }
  }
}
.remove-modal {
  font-size: 39px;
  .icon-close-with-circle {
    cursor: pointer;
  }
  .remove-modal-tile {
    color: $white;
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    text-transform: capitalize;
  }
  p {
    color: #c9c9c9;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin: 9px 0 21px 0;
  }
  .modal-body-form {
    padding: 23px 27px 27px 27px !important;
    .successful-note {
      background-color: rgba(204, 51, 51, 0.1);
      padding: 13px;
      border-radius: 9px;
      margin-top: 18px;
      p {
        margin: 0;
        font-size: 14px;
        line-height: 26px;
        span {
          color: #fff;
        }
      }
    }
  }
  .footer-outline-btn {
    color: #cc3333;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    margin-right: 31px;
    border: 0;
    padding: 0;
    &:hover {
      background-color: transparent !important;
    }
  }
}

.account-verification-modal {
  .modal-dialog {
    max-width: 680px;
    width: 100%;
  }
  .boarding-title {
    margin-bottom: 49px;
    h1 {
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
    }
  }
  .login-inner {
    padding: 27px 27px 0 27px;
    margin-top: 0;
  }
  .account-description {
    font-size: 16px;
    line-height: 30px;
    @media (max-width: $medium) {
      font-size: 14px;
      line-height: 28px;
    }
  }
  .account-description-short {
    font-size: 16px;
    @media (max-width: $medium) {
      font-size: 14px;
      line-height: 19px;
    }
  }
  .form-label-otp {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 35px;
    @media (max-width: $medium) {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 30px;
    }
  }
  input {
    background-color: #303039 !important;
    @media (max-width: $medium) {
      max-width: 40px;
      height: 40px !important;
      margin-right: 14px !important;
      font-size: 14px !important;
    }
  }
  .account-verify-payment {
    .mb-20 {
      margin-bottom: 0 !important;
    }
    .login-submit {
      margin: 0 -38px 0 -27px;
      padding: 22px 27px;
      background-color: rgba(44, 45, 51, 0.2);
      border-bottom-left-radius: 13px;
      border-bottom-right-radius: 13px;
      justify-content: flex-end;
      border-top: 1px solid #2c2d33;
      @media (max-width: $medium) {
        margin: 0 -26px 0 -27px;
        display: flex;
        justify-content: flex-end;
      }
      button {
        max-width: 147px;
      }
    }
  }
}

// unlock power modal scss
.edit-email-modal {
  z-index: 9999;
  .modal-dialog {
    max-width: 699px;
    width: 100%;
  }
  .subprobs-dollar {
    font-weight: 700;
    font-size: 27px;
    line-height: 31px;
    color: #FFFFFF;
    margin: 0;
  }
  .annually {
    margin-left: 0px;
    @media (max-width: 576px) {
      display: unset;
    }
  }
  .addon-des {
    padding: 18px;
    border-radius: 9px;
    margin: 21px 0 0 0 !important;
    @media (max-width: 576px) {
      margin: 21px 0 0 0 !important;
    }
    p {
      font-size: 14px;
      line-height: 27px;
      &:first-child {
        margin-bottom: 12px !important;
      }
    }
  }
}
.unlock-power {
  .unlock-power-title {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    margin: 0;
  }
  .unlock-power-subtitle {
    color: #C9C9C9;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    margin: 13px 0 22px 0;
  }
}
.edit-email-package-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 576px) {
    display: unset;
  }
  .edit-email-package-left {
    @media (max-width: 576px) {
      margin-bottom: 27px;
    }
    .edit-package-left-title {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      margin: 0 0 24px 0;
      @media (max-width: 576px) {
        margin: 0 0 12px 0;
      }
    }
  }
  .edit-email-package-right {
    .edit-package-right-title {
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      margin: 0 0 8px 0;
    }
  }
  .active {
    background-color: #4F505C;
    padding: 11px 14px;
    border-radius: 18px;
  }
}
.add-new-address {
  .form-mt-space {
    margin-bottom: 13px !important;
    @media (max-width: $small-medium) {
      margin-bottom: 10px !important;
    }
  }
}
.change-password-form {
  padding: 27px 27px 0 27px !important;
  margin-bottom: 6px;
  .change-new-password {
    .form-mt-space  {
      margin-bottom: 30px;
    }
  }
}
.upload-csv-new {
  .form-mt-space {
    @media (max-width: $small-medium) {
      margin-bottom: 0;
    }
  }
}
.edit-plan-modal-textarea {
  margin-bottom: 13px;
}
.graph-info-modal {
  padding: 40px 27px 27px 27px !important;
  @media (max-width: $small-medium) {
    padding: 20px 15px 27px 15px !important;
  }
  .graph-info-container {
    display: flex;
    align-items: center;
    .graph-info-title {
      color: #AAABAE;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      text-transform: uppercase;
      margin-bottom: 20px;
      @media (max-width: $small-medium) {
        font-size: 10px;
      }
    }
  }
  .graph-info-left {
    max-width: 165px;
    width: 100%;
    margin-right: 32px;
    @media (max-width: $small-medium) {
      margin-right: 10px;
    }
  }
  .graph-info-middle {
    max-width: 133px;
    width: 100%;
    margin-right: 32px;
    @media (max-width: $small-medium) {
      margin-right: 10px;
    }
  }
  .graph-info-right {
    max-width: 62px;
    width: 100%;
  }
  .graph-info-list {
    list-style-type: none;
    .graph-item {
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 41px;
      @media (max-width: $small-medium) {
        font-size: 14px;
      }
    }
  }
}
.custom-bar-container {
  display: flex;
  justify-content: center;
  height: 141px;
}
.custom-bar-representation {
  top: 1px;
  display: grid;
  position: relative;
  right: 47px;
  max-width: 21px;
  width: 100%;
  height: 96px;
  margin: auto;
  .custom-bar-item {
    width: 0px;
    height: 100%;
    display: block;
    // background: white;
    &.darkest-red {
      background-color:#FD181C;
    }
    &.orange {
      background-color:#FD5F20;
    }
    &.tinted-yellow {
      background-color:#FEC662;
    }
    &.yellow {
      background-color:#FFFF2E;
    }
    &.light-green {
      background-color:#C6FF93;
    }
    &.dark-green {
      background-color:#13A649;
    }
    &.darkest-green {
      background-color:#0C7410;
    }
  }
}
.unlock-power-new {
  padding: 20px 27px 31px 27px !important;
  .edit-email-package-left {
    .edit-package-left-title {
      margin: 0 0 12px 0;
    }
    .subprobs-dollar {
      font-size: 32px;
      line-height: 38px;
    }
  }
  .edit-email-package-right {
    &.active {
      padding-right: 44px;
    }
    .edit-package-right-title {
      margin: 0 0 12px 0;
      font-size: 16px;
      line-height: 19px;
    }
    .subprobs-dollar {
      font-size: 32px;
      line-height: 38px;
      .default-month {
        font-size: 16px;
        line-height: 19px;
      }
      .annually {
        padding: 6px 12px;
        font-size: 14px;
        line-height: 17px;
        top: -5px;
      }
    }
  }
}
.disabled-probe {
  .modal-dialog {
    max-width: 412px;
    width: 100%;
  } 
  .disabled-probe-modal {
    padding: 26px 27px 30px 27px !important;
    p {
      color: #C9C9C9;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 180%;
      margin: 0 0 30px;
    }
    .disabled-probe-contact {
      color: #C33;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;
      text-transform: uppercase;
    }
  }
}