.header-mobile-left {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  @media (max-width: $medium) {
    margin-top: -49px;
  }
  .navbar-toggler {
    padding: 0;
    margin: 0;
    position: absolute;
    left: 20px;
  }
  .mobile-logo {
    width: 77px;
    img {
      width: 100%;
    }
  }
}

// custom menu burger 

.offcanvas-start {
  max-width: 240px !important;
  width: 100% !important;
}

.menu-burger-body {
  padding: 20px;
  background-color: #21222D;
  .sidebar-profile {
    margin: 8px 0 50px 0;
    @media (max-width: $medium) {
      margin: 8px 0 40px 0;
    }
    .sidebar-close {
      margin-bottom: 28px;
      cursor: pointer;
    }
  }
  .sidebar-container {
    .main-navigation {
      font-size: 13px;
      line-height: 25px;
      margin-bottom: 16px;
    }
  }
  .menu-dropdown {
    .dropdown {
      button {
        padding: 0;
        margin: 0;
        width: 100%;
        justify-content: left;
      }
    }
    .profile-img {
      margin-right: 10px;
      width: 38px;
      height: 38px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .dropdown-img {
      margin-left: 21px;
    }
    .dropdown-menu {
      min-width: 96%;
    }
  }
  .accordion {
      .accordion-item {
          background-color: transparent;
          border: 0;
      }

      .accordion-header {
          margin-bottom: 0;
          border: 1px solid;
          border-color: #353535;
          border-right: 0;
          border-left: 0;
      }

      .accordion-button {
          font-size: 14px;
          text-transform: uppercase;
          font-weight: 500;
          color: #fff;
          background-color: transparent;
          box-shadow: none;
          position: relative;
      }
  }

  .accordion-body {
      padding: 0;

      .faviourt-list {
          list-style: none;
          padding: 8px 0 8px 0;
          margin: 0;

          li {
              margin: 0;
              font-size: 15px;
              color: #ccc;
              height: 48px;
              display: flex;
              align-items: center;
              padding-left: 14px;

              &:hover {
                  background-color: #ff5c39;
                  color: #fff;

                  span {
                      color: #fff;
                  }
                  .message-count {
                      background-color: #fff;
                      border-radius: 3px;
                      color: #ff5c39;
                  }
              }

              .faviourt-list-menu {
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                  width: 100%;

                  span {
                      padding-right: 8px;
                      font-size: 24px;
                  }
              }
          }
      }
  }
  .sidebar-bottom {
    width: 200px;
  }
}

//end 