.onboarding {
  position: relative;
  min-height: 100%;
}
.onboarding-container {
  padding: 20px 104px 238px;
  width: 100%;
  @media (max-width: $extra-large) {
    padding: 20px 35px 238px;
  }
  @media (max-width: $medium) {
    padding: 30px 20px 106px;
  }
  .onboarding-frame {
    color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    @media (max-width: $medium) {
      display: none;
    }
    @media (min-width: $small-medium) and (max-width: $medium) {
      display: block;
      width: 175px;
    }
  }
  .onboarding-shadow {
    position: absolute;
    top: 0;
    left: 0;
    height: 607px;
    @media (max-width: $medium) {
      display: none;
    }
    @media (min-width: $small-medium) and (max-width: $medium) {
      display: block;
      width: 385px;
    }
  }
}
.onboarding-bottom-wrap {
  display: flex;
  justify-content: space-between;
  margin-top: 0;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  .onboarding-bottom {
    @media (max-width: $extra-large) {
      width: 300px;
    }
    @media (max-width: $medium) {
      width: 180px;
    }
    @media (min-width: $small-medium) and (max-width: $medium) {
      width: 265px;
    }
  }
}
// .onboarding-bottom-wrap-left {
//   background-image: url('./../../img/onboarding-bottom-left.png');
//   background-position: left bottom;
//   background-repeat: no-repeat;
//   position: relative;
// }

.onboarding-left {
  padding-right: 96px !important;
  @media (max-width: $extra-large) {
    padding-right: 30px !important;
  }
  @media (min-width: $small-medium) and (max-width: $medium) {
    padding-right: 80px !important;
    margin-top: 50px;
  }
  .logo {
    margin-top: 95px;
    position: relative;
    z-index: 1;
    @media (max-width: $medium) {
      margin-top: 0;
      width: 77px;
      height: 38px;
      padding-right: 0px !important;
    }
    @media (min-width: $small-medium) and (max-width: $medium) {
      width: 145px;
      height: auto;
    }
  }
  .logo-none {
    @media (max-width: $medium) {
      display: none !important;
    }
  }
  .login-title {
    font-size: 46px;
    font-weight: 500;
    line-height: 60px;
    color: $white;
    margin: 22px 0 17px;
    @media (max-width: $extra-large) {
      font-size: 30px;
      line-height: 46px;
    }
    @media (max-width: $medium) {
      display: none;
    }
    @media (min-width: $small-medium) and (max-width: $medium) {
      display: block;
    }
  }
  .login-info {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: $grey;
    @media (max-width: $extra-large) {
      font-size: 15px;
    }
    @media (max-width: $medium) {
      display: none;
    }
    @media (min-width: $small-medium) and (max-width: $medium) {
      display: block !important;
    }
  }
}
.onboarding-right {
  .login-inner {
    padding-left: 48px;
    margin-top: 72px;
    @media (max-width: $medium) {
      padding-left: 0;
      margin-top: 30px;
    }
  }
}
.boarding-title {
  margin-bottom: 49px;
  @media (max-width: $extra-large) {
    margin-bottom: 40px;
  }
  @media (max-width: $medium) {
    margin-bottom: 40px;
  }
  h1 {
    font-size: 36px;
    font-weight: 600;
    line-height: 41px;
    color: $white;
    @media (max-width: $extra-large) {
      font-size: 28px;
      line-height: 30px;
    }
    @media (max-width: $medium) {
      font-size: 26px;
      line-height: 30px;
    }
  }
  p {
    margin: 14px 0 0;
    font-size: 16px;
    font-weight: 400;
    color: $grey;
    line-height: 19px;
    @media (max-width: $extra-large) {
      font-size: 14px;
    }
    @media (max-width: $medium) {
      margin: 14px 0 0;
      font-size: 14px;
      line-height: 16px;
    }
  }
  .otp-edit {
    color: $white;
    font-weight: 600;
    line-height: 21px;
    margin-top: 9px;
    display: flex;
    align-items: center;
    @media (max-width: $medium) {
      margin-top: 14px;
    }
    .icon-edit {
      margin-left: 6px;
      cursor: pointer;
      font-size: 17px;
    }
    .disable {
      cursor: default !important;
    }
  }
  &.verified-successfully-wrap {
    margin-bottom: 33px;
    text-align: center;
    @media (max-width: $medium) {
      margin-bottom: 31px;
    }
    .verified-successfully {
      margin-top: 60px;
    }
    .verified-successfully-title {
      font-size: 30px;
      line-height: 35px;
      color: #ffffff;
      margin-top: 45px;
      font-weight: 600;
      @media (max-width: $medium) {
        font-size: 22px;
        line-height: 26px;
      }
    }
    .verified-successfully-des {
      font-weight: 400;
      line-height: 28px;
      @media (max-width: $medium) {
        padding: 0 45px;
      }
    }
  }
}
.verified-successfully-btn {
  button {
    max-width: 355px;
    width: 100%;
    margin: auto;
    font-size: 16px;
    line-height: 19px;
    @media (max-width: $medium) {
      max-width: 240px;
    }
  }
}
.form-label-otp {
  color: $white;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 35px;
  @media (max-width: $large) {
    font-size: 16px;
    margin-bottom: 30px;
  }
  @media (max-width: $medium) {
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 30px;
  }
}
.opt-group {
  margin-bottom: 36px;
  @media (max-width: $large) {
    margin-bottom: 30px;
  }
  @media (max-width: $medium) {
    margin-bottom: 30px;
  }
}
.time-remaining-wrap {
  color: $white;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 31px;
  @media (max-width: $medium) {
    font-size: 15px;
  }
  @media (max-width: $medium) {
    font-size: 14px;
    line-height: 15px;
  }
  .left-time {
    font-weight: 700;
    color: #cc3333;
    @media (max-width: $medium) {
      position: relative;
      top: 1px;
    }
  }
}
.sign-up-terms {
  margin: 0 0 25px 0;
  @media (max-width: $medium) {
    margin: 0 0 34px 0;
  }
  .sign-up-terms-text {
    font-size: 18px;
    color: #fff;
    font-weight: 500;
    line-height: 25px;
    @media (max-width: $large) {
      font-size: 16px;
    }
    @media (max-width: $medium) {
      font-size: 14px;
      font-weight: 500;
      line-height: 28px;
    }
    .sign-up-terms-link {
      color: #aaabae;
      text-decoration: underline;
      cursor: pointer;
      padding-left: 4px;
      &.accomplished {
        color: #cc3333;
      }
    }
    .form-check-label {
      top: 7px !important;
    }
    .text-link {
      top: 1px;
      position: relative;
    }
  }
  .sign-up-terms-text-new {
    display: flex;
    align-items: center;
  }
  .successful-note {
    max-width: 400px;
    margin-top: 20px;
    padding: 15px;
    @media (max-width: $medium) {
      max-width: 100%;
    }
    p {
      color: #aaabae;
      span {
        color: #fff;
      }
    }
  }
}
.boarding-title-signup {
  margin-bottom: 29px;
  @media (max-width: $medium) {
    margin-bottom: 30px;
  }
  h1 {
    font-size: 36px;
    font-weight: 600;
    line-height: 41px;
    color: $white;
    @media (max-width: $medium) {
      font-size: 26px;
      line-height: 30px;
    }
  }
  p {
    margin: 14px 0 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: $grey;
    @media (max-width: $medium) {
      margin: 14px 0 0;
      font-size: 14px;
      line-height: 16px;
    }
  }
}
.boarding-login {
  .edit-page-tag-line {
    margin: 6px 0 0 !important;
  }
}

.btn-container {
  text-align: center;
  margin-bottom: 30px;
  .btn {
    width: 100%;
    max-width: 290px;
  }
}

.signin-bottom {
  margin-top: 21px;
  text-align: center;
  font-size: 15px;
  line-height: 17px;
  font-weight: 400;
  color: #fff;
  @media (max-width: $medium) {
    margin-top: 16px;
    font-size: 14px;
    line-height: 16px;
  }
  a {
    color: #cc3333;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    line-height: 17px;
    font-weight: 600;
    margin-left: 5px;
    cursor: pointer;
    position: relative;
    z-index: 1;
    @media (max-width: $medium) {
      font-size: 14px;
      line-height: 16px;
    }
  }
  p {
    margin-top: 10px;
  }
}

.form-check {
  .form-check-input {
    background-color: transparent;
    border-color: $grey !important;
    border: 1px solid;
    width: 19px;
    height: 19px;
    border-radius: 3px !important;
    box-shadow: none;
    cursor: pointer;
    &:checked {
      background-color: #cc3333 !important;
      border-color: #cc3333 !important;
      border: 4px solid;
      width: 19px;
      height: 19px;
      border-radius: 2px !important;
      box-shadow: none;
    }
    &:checked[type='checkbox'] {
      background-image: url('../../icons/checkbox-tick.svg') !important;
      background-repeat: no-repeat;
    }
    &:focus {
      box-shadow: none !important;
    }
  }
  .form-check-label {
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    line-height: 17px;
    margin-left: 9px;
    position: relative;
    top: 6px;
    @media (max-width: $medium) {
      font-size: 13px;
      line-height: 15px;
      top: 5px;
    }
  }
}
.remember-me-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .forgot-link {
    font-size: 15px;
    font-weight: 600;
    line-height: 17px;
    color: $white;
    cursor: pointer;
    @media (max-width: $medium) {
      font-size: 13px;
      line-height: 15px;
    }
  }
}

.custom-error-list {
  margin-top: -10px;
  margin-bottom: 30px;
  .list-group {
    background-color: #4f505c;
    padding: 15px 14px 15px;
  }
  .list-group-item {
    color: #62b266;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    background-color: transparent;
    border: 0;
    margin-bottom: 9px;
    padding: 0;
    &:last-child {
      margin-bottom: 0;
    }
    .error-green {
      display: flex;
      align-items: center;
      .green-tick {
        margin-right: 11px;
        width: 12px;
      }
    }
    .error-grey {
      color: $grey;
      display: flex;
      align-items: center;
      .grey-tick {
        margin-right: 11px;
        width: 12px;
      }
    }
  }
}
.login-submit {
  display: flex;
  @media (max-width: $medium) {
    display: unset;
  }
  .login-previous {
    margin-right: 20px;
    width: 140px;
    @media (max-width: $medium) {
      width: 100%;
      margin-bottom: 20px;
    }
  }
}

// otp send css
.otp-sent {
  margin-top: 3px;
  div {
    input {
      max-width: 53px;
      width: 100% !important;
      height: 53px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 24px;
      font-weight: 600;
      line-height: 20px;
      box-shadow: unset;
      background-color: #21222d;
      border: 0;
      border-radius: 7px;
      margin-right: 44px;
      caret-color: #cc3333;
      &:focus {
        border: 1px solid #cc3333;
        box-shadow: unset;
        outline: unset;
      }
      &:focus-visible {
        border: 1px solid #cc3333;
        box-shadow: unset;
        outline: unset;
      }
      @media (max-width: $medium) {
        width: 49px !important;
        height: 49px;
        margin-right: 41px;
      }
    }
  }
}
// subscription top header
.header-nav {
  background-color: #14151f;
  height: 71px;
  padding: 0 7px 0 3px;
  border-bottom: 1px solid #2c2d33;
  @media (max-width: $medium) {
    background-color: unset;
    border-bottom: 0;
  }
  .header-logo {
    padding: 0;
    margin: 0;
    img {
      width: 71px;
      height: 35px;
    }
  }
}
.header-container {
  .navbar-collapse {
    justify-content: right;
  }
}

.menu-dropdown {
  .dropdown {
    // transition: 0.3s;
    button {
      border: 0;
      display: flex;
      align-items: center;
      text-transform: inherit;
      background-color: transparent !important;
      box-shadow: unset;
      // transition: 0.3s;
      &:hover {
        background-color: transparent;
        box-shadow: unset;
      }
      &.show {
        .dropdown-img {
          img {
            transform: rotate(180deg);
            // transition: 0.3s;
          }
        }
      }
    }
  }

  .menu-dropdown-toggle {
    background-color: transparent !important;
    padding: 0;
    &::after {
      display: none;
    }

    .profile-img {
      margin-right: 7px;
      background-color: #e3f0f6;
      width: 33px;
      height: 33px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    .dropdown-img {
      margin-left: 6px;
      img {
        width: 12px;
      }
    }
  }
  .header-user-detail {
    text-align: left;
    .user-title {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 3px;
      color: $white;
      text-transform: capitalize;
      display: block;
      @media (min-width: $small-medium) and (max-width: $medium) {
        line-height: 22px;
      }
    }
    .user-subtitle {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: $grey;
      text-transform: capitalize;
    }
  }

  .dropdown-menu-list {
    font-size: 15px;
    font-weight: 400;
    line-height: 2.4;
    color: #c6c6c6;
    height: 36px;
    text-transform: inherit;
    display: flex;
    align-items: center;
    &:hover {
      background-color: #ff5c39;
      color: #fff;
      cursor: default !important;
    }
  }

  .dropdown-menu {
    background-color: #303039;
    left: inherit;
    top: 59px;
    right: 0;
    min-width: 182px;
    border: 0;
    padding: 0;
    .dropdown-item {
      font-size: 13px;
      line-height: 20px;
      font-weight: 400;
      padding: 12px 12px;
      color: #fff;
      border-bottom: 1px solid #4a4b50;
      &:last-child{
        border-bottom: unset;
      }
      &:hover {
        background-color: transparent;
        color: #cc3333;
        svg {
          path {
            fill: #cc3333;
          }
        }
      }
      &.logout-profile {
        color: #fff;
        .icon-username {
          &::before {
            content: "\e918";
            color: #fff;
          }
        }
        svg {
          path {
            fill: #fff;
          }
        }
        &:hover {
          background-color: transparent;
          color: #cc3333;
          svg {
            path {
              fill: #cc3333;
            }
          }
          .icon-username {
            &::before {
              content: "\e918";
              color: #cc3333;
            }
          }
        }
      }
      .logout-icon {
        margin-right: 11px;
        img {
          margin: 0;
        }
      }
    }
  }
}
.onboarding-top-form {
  .login-submit {
    button {
      max-width: 400px;
      @media (max-width: $medium) {
        max-width: 100%;
      }
    }
  }
}
.winery-address {
  .form-mt-space {
    margin-bottom: 20px;
  }
}
.icon-main-menu{
  font-size: 21px;
  min-width: 30px;
}
.successfuly-reset-btn {
  margin-left: 48px;
  @media (max-width: $medium) {
    margin-left: 0;
  }
}
.header-user-detail-mobile {
  .user-title {
    @media (max-width: $small-medium) {
      width: 118px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
.change-profile {
  .icon-lock {
    font-size: 19px;
  }
}