.mb-18 {
  margin-bottom: 18px;
}
.mb-26 {
  margin-bottom: 26px;
}
.edit-profile-btn {
  width: 169px !important;
}
.text-cap {
  text-transform: uppercase;
}
.w-auto {
  width: auto !important;
}
.cursor-pointer {
  cursor: pointer;
}
.three-dot-outside-sec {
  .icon-three-dot {
    padding: 0;
    width: 45px;
    height: 45px;
    border: 1px solid #c33;
    border-radius: 10px;
    margin-left: 20px;
    .dropdown-toggle {
      height: 45px;
      min-width: 45px;
      &:hover {
        border: 1px solid transparent;
      }
      &::after {
        width: auto;
        height: auto;
      }
    }
  }
}
.dashboard-welcome {
  .dashboard-wel-wrapper {
    .name {
      font-size: 17px;
      font-weight: 400;
      margin-bottom: 15.5px;
      color: #fff;
      @media (max-width: 767px) {
        font-size: 16px;
        margin-bottom: 15px;
      }
    }
    .welcome {
      font-size: 23px;
      font-weight: 700;
      margin-bottom: 33.5px;
      color: #fff;
      @media (max-width: 767px) {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 25px;
      }
    }
  }
  .dashboard-card-wrapper {
    display: flex;
    align-items: center;
    margin: 0 -9px 44.5px -9px;
    @media (max-width: 767px) {
      flex-direction: column;
      margin: 0;
    }
    @media (min-width: $small-medium) and (max-width: 1100px) {
      display: unset;
    }
    .card-left,
    .card-right {
      width: 50%;
      height: 265px;
      border-radius: 14px;
      margin: 0 9px;
      padding: 26px 35px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-repeat: no-repeat;
      background-position: bottom bottom;
      @media (max-width: 767px) {
        width: 100%;
        padding: 30px;
        margin-bottom: 20px;
      }
      @media (min-width: $small-medium) and (max-width: 1100px) {
        width: 100%;
      }
      &.bg-left {
        background: url('../../img/bg-company.png') no-repeat bottom right;
        background-color: #e3f5ff;
        background-size: 220px 200px;
        @media (max-width: 767px) {
          height: 282px;
          background-size: 200px 160px;
        }
        @media (min-width: $small-medium) and (max-width: 1100px) {
          margin-bottom: 27px;
        }
      }
      &.bg-right {
        // background: url('../../img/removebg-preview.png') no-repeat bottom right;
        // background-color: #e3ecf6;
        // background-size: 200px 160px;
        @media (max-width: 767px) {
          height: 377px;
          justify-content: unset;
          background-size: 200px 160px;
        }
      }
    }
    .pt-25 {
      padding-top: 25px;
      @media (max-width: 767px) {
        padding-top: 0px;
      }
    }
    .card-data-sec {
      @media (max-width: 767px) {
        margin-bottom: 30px;
      }
      .card-num {
        font-size: 50px;
        font-weight: 900;
        margin-bottom: 10px;
        color: #0f101a;
        line-height: 50px;
        @media (max-width: 767px) {
          font-size: 48px;
          font-weight: 900;
          margin-bottom: 8px;
        }
      }
      .card-total {
        font-size: 18px;
        font-weight: 400;
        color: #21222D;
      }
    }
  }
  .dashboard-filter-wrapper {
    .filter-heading {
      font-size: 19.5px;
      font-weight: 500;
      color: #aaabae;
      margin-bottom: 28.5px;
      @media (max-width: 767px) {
        font-size: 18px;
        margin-top: 30px;
      }
    }
  }
  .dashboard-filter-space-top {
    @media (min-width: $small-medium) and (max-width: 1100px) {
      margin-top: 35px;
    }
  }
}
.admin-dashboard {
  .dashboard-card-wrapper {
    display: unset;
    margin: 0;
  }
  .card-left,
    .card-right {
      width: 100% !important;
      height: 228px !important;
      border-radius: 14px;
      padding: 26px 35px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-repeat: no-repeat;
      background-position: bottom bottom;
      margin: 0 0 22px 0 !important;
      @media (max-width: 767px) {
        width: 100%;
        padding: 30px;
        margin-bottom: 20px;
      }
      &.bg-left {
        background: url('../../img/bg-company.png') no-repeat bottom right;
        background-color: #e3f5ff;
        background-size: 200px 160px;
        @media (max-width: 767px) {
          height: 282px;
          background-size: 200px 160px;
        }
      }
      &.bg-middle {
        background: url('../../img/bg2.png') no-repeat bottom right;
        background-color: #FFFCF9;
        background-size: 200px 160px;
        @media (max-width: 767px) {
          height: 377px;
          justify-content: unset;
          background-size: 200px 160px;
        }
      }
      &.bg-right-admin {
        background: url('../../img/bg3.png') no-repeat bottom right;
        background-color: #E5ECF6;
        background-size: 200px 160px;
        @media (max-width: 767px) {
          height: 377px;
          justify-content: unset;
          background-size: 200px 160px;
        }
        .card-total {
          font-size: 18px;
          font-weight: 400;
          color: #21222D;
        }
      }
    .card-data-sec {
      &:first-child {
        margin-right: 100px;
      }
    }
  }
}
.admin-main-title {
  height: 100%;
  display: flex !important;
}
.admin-main-third-title {
  align-items: center;
}
.admin-main-subtitle {
  display: flex;
  align-items: center;
  .card-data-subsec {
    margin-right: 70px;
    &:last-child {
      margin-right: 0;
    }
    .card-num {
      font-weight: 900;
      font-size: 30px;
      line-height: 33px;
      color: #4D4E57;
    }
    .card-total {
      font-weight: 400;
      font-size: 15px;
      line-height: 17px;
      color: #4D4E57;
      margin-top: 7px;
    }
  }
  .card-data-subsec2 {
    margin-right: 124px;
  }
}
.filter-sec {
  padding: 27px;
  background-color: #21222d;
  border-radius: 14px;
  @media (max-width: 767px) {
    padding: 20px;
  }
  .filter-by-heading {
    font-size: 19.5px;
    font-weight: 500;
    color: #7b7b7b;
    margin-bottom: 25px;
    @media (max-width: 767px) {
      font-size: 18px;
    }
    span {
      font-size: 16px;
      font-weight: 400;
      color: #aaabae;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
  .filter-by-form {
    margin-bottom: 34px;
    @media (max-width: $medium) {
      margin-bottom: 0;
    }
  }
  .filter-by-btn {
    .modal-btn-submit {
      width: 160px;
      height: 44.5px;
      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }
}
.multi-select-custom {
  .wrap-select {
    .select__control {
      background-color: #303039;
    }
    .select__value-container {
      padding-left: 15px;
    }
  }
  .form-mt-space {
    margin-bottom: 0;
    @media (max-width: $medium) {
      margin-bottom: 30px;
    }
  }
}
.date-select-custom {
  input {
    background-color: #303039;
    padding-left: 15px !important;
  }
  .form-mt-space {
    margin-bottom: 0px;
    @media (max-width: $medium) {
      margin-bottom: 30px;
    }
  }
}
.react-datepicker__time-container {
  border-left: 1px solid #4a4b50 !important;
}
.date-time-select-custom {
  .react-datepicker__navigation--next {
    top: 3px;
    right: 12px;
  }
  .react-datepicker {
    display: flex;
    background-color: transparent;
  }
  .react-datepicker__time-list {
    background-color: #393a42;
    color: #fff;
    height: 199px !important;
    border-radius: 0 0 7px 7px;
    &::-webkit-scrollbar-thumb {
      display: none;
      width: 0;
    }
    &::-webkit-scrollbar-track:vertical {
      display: none;
      width: 0;
    }
    @media (max-width: $medium) {
      height: auto;
    }
    .react-datepicker__time-list-item {
      padding: 8px 10px !important;
      &:hover {
        background-color: #c33 !important;
        color: #fff;
      }
    }
  }
  .react-datepicker-time__header {
    color: #fff;
  }
  .react-datepicker__time {
    background-color: #393a42;
    border-radius: 0 0 7px 7px;
    .react-datepicker__time-list-item--selected {
      background-color: #cc3333 !important;
    }
  }
}
.only-change-datetime {
  .react-datepicker__header--has-time-select {
    border-top-right-radius: 0;
  }
  .react-datepicker__header--time {
    border-top-left-radius: 0;
  }
  .react-datepicker__month {
    border-bottom-right-radius: 0;
  }
  .react-datepicker__time-list {
    border-radius: 0;
  }
}
.winery-address-sec {
  display: flex;
  align-items: center;
  @media (max-width: $small-medium) {
    display: unset;
  }
  .checkbox-input {
    margin-left: 22px;
    margin-top: 23px;
    @media (max-width: $small-medium) {
      margin-left: 0;
      margin-top: -9px;
      margin-bottom: 40px;
    }
  }
  .form-mt-space {
    &:first-child {
      flex: 1;
    }
  }
}
// select-probes css
.dashboard-selected-probes {
  background-color: #21222d;
  padding: 27px;
  border-radius: 13px;
  margin-top: 18px;
  .selected-probes-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
    color: #7B7B7B;
    margin-bottom: 27px;
  }
}
.selected-probes-wrapper {
  gap: 27px;
  .selected-probes-card {
    background-color: #303039;
  }
}
.selected-probes-card {
  background-color: #21222D;
  padding: 27px;
  border-radius: 9px;
  width: 48.6%;
  position: relative;
  .switch-main {
    @media (max-width: $small-medium) {
      position: absolute;
      top: 27px;
      right: 27px;
    }
  }
  @media (max-width: $medium) {
    width: 100%;
  }
  .selected-probes-subtitle {
    font-size: 21px;
    font-weight: 700;
    line-height: 25px;
    color: #fff;
    margin-bottom: 24px;
  }
  .list-group {
    width: 100%;
  }
  .list-group-item {
    background-color: transparent;
    border: 0;
    padding: 0;
    width: 50%;
    @media (max-width: $medium) {
      width: 100% !important;
      margin-bottom: 27px !important;
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
    @media (min-width: $small-medium) and (max-width: $medium) {
      width: 50% !important;
      margin-bottom: 27px !important;
    }
  }
}

.close-icon-right {
  cursor: pointer;
  position: absolute;
  right: -7px;
  top: -7px;
  font-size: 21px;
  background-color: #303039;
  border-radius: 50%;
}

.selected-probes-btn {
  margin-top: 36px;
  @media (max-width: $medium) {
    margin-top: 30px;
  }
  span {
    font-weight: 400;
    font-style: italic;
    margin-left: 5px;
    @media (max-width: $medium) {
      margin-left: 0;
      width: 100%;
      display: flex;
      margin-top: 6px;
      justify-content: center;
    }
    @media (min-width: $small-medium) and (max-width: $medium) {
      margin-left: 6px;
      width: auto;
      margin-top: 0;
      display: unset;
    }
  }
  button {
    text-transform: uppercase;
    font-weight: 600;
    padding: 0 23px;
    font-size: 14px !important;
    @media (max-width: $medium) {
      display: flow-root !important;
      height: auto !important;
      padding: 16px;
      width: 100%;
    }
    @media (min-width: $small-medium) and (max-width: $medium) {
      display: unset !important;
    }
  }
}

// admin-custom-tabs scss
.user-custom-tabs {
  margin-top: 35px;
  margin-bottom: 45px;
  .user-custom-tabs-wrapper {
    border: 0;
    .nav-item {
      &:first-child {
        .nav-link {
          background-color: #21222d;
          color: #aaabae;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          &.active,
          &:hover {
            background-color: #4f505c;
            color: #fff;
          }
        }
      }
      &:last-child {
        .nav-link {
          background-color: #21222d;
          color: #aaabae;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          &.active,
          &:hover {
            background-color: #4f505c;
            color: #fff;
          }
        }
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      .nav-link {
        background-color: #21222d;
        color: #aaabae;
        height: 53px;
        font-size: 18px;
        font-weight: 600;
        line-height: 21px;
        border-radius: 7px;
        border: 0;
        position: relative;
        @media (max-width: $medium) {
          height: 50px;
          font-size: 15px;
          font-weight: 600;
          line-height: 18px;
        }
        &.active {
          background-color: #4f505c;
          color: #fff;
          border: 0;
          &::after {
            content: '';
            width: 13px;
            height: 13px;
            background-color: #4f505c;
            position: absolute;
            bottom: -6px;
            transform: rotate(45deg);
            border-radius: 2px;
            left: 0;
            right: 0;
            margin: auto;
          }
        }
      }
    }
  }
}

.btn-status {
  height: 30px;
  width: 100px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 16px;
  font-weight: 600;
  padding: 9px 5px 7px;
  &.active {
    background: #d4e2d5;
    color: #43a047;
  }
  &.deactive {
    background: #fcd4d3;
    color: #c33;
  }
}
.admin-heading-sec {
  .admin-header-title {
    margin-bottom: 35px;
  }
}

.dashboard-data-tabs {
  .dashboard-title {
    font-size: 23px;
    font-weight: 700;
    line-height: 27px;
    color: #fff;
    margin-bottom: 26px;
    span {
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      color: #aaabae;
      font-style: italic;

    }
  }
  .user-custom-tabs {
    margin-top: 0;
  }
}

// multiple tabs scss
.dashboard-multiple-tabs {
  margin-top: 47px;
  margin-bottom: 47px;
  .user-custom-tabs-wrapper {
    border: 0;
  }
  .nav-tabs {
    background-color: #21222d;
    padding: 0 27px;
    border-radius: 7px;
  }
  .nav-item {
    max-width: 120px;
    width: 100%;
    min-width: 80px;
    .nav-link {
      font-size: 18px;
      font-weight: 400;
      line-height: 21px;
      border: 0;
      width: auto;
      flex-wrap: wrap;
      display: flex;
      -webkit-flex-wrap: wrap;
      padding: 16px 0;
      color: #aaabae;
      &.active,
      &:hover {
        font-weight: 500;
        background-color: transparent;
        color: #c33;
        border-bottom: 3px solid #c33;
      }
    }
  }
  .tab-content {
    .tab-pane {
      color: #fff;
    }
  }
}

// dashboard-probe-details scss
.dashboard-probe-details {
  position: relative;
  .dashboard-probe-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
    color: #7b7b7b;
    margin-bottom: 18px;
  }
  .list-group {
    margin-top: 37px;
    .list-group-item {
      width: auto;
      margin-right: 250px;
      @media (max-width: $medium) {
        width: 100%;
        margin-bottom: 30px !important;
        margin-right: 0;
      }
    }
  }
  .dashboard-probe-wrapper {
    margin-bottom: 0;
  }
  @media (max-width: $small-medium) {
    .switch-main {
      top: -40px;
      right: 0;
    }
  }
}

.dashboard-probe-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  .dashboard-probe-subtitle {
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    color: #ffffff;
  }
}
.switch-main {
  display: flex;
  align-items: center;
  @media (max-width: $medium) {
    position: absolute;
    top: 27px;
    right: 27px;
  }
  .switch-main-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #fff;
    margin-right: 8px;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 23px;
  }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #aaabae;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .slider:before {
    position: absolute;
    content: '';
    height: 19px;
    width: 19px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  input:checked + .slider {
    background-color: #43a047;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px #43a047;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px);
  }

  .slider.round {
    border-radius: 34px;
  }
  .slider.round:before {
    border-radius: 50%;
  }
}
// for account profile
.account-profile-sec {
  margin-bottom: 18px;
  position: relative;
  .profile-logo-sec {
    display: flex;
    padding-bottom: 31px;
    margin-bottom: 27px;
    border-bottom: 1px dashed rgba(123, 123, 123, 0.5);
    @media (max-width: 767px) {
      flex-direction: column;
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
    .profile-logo-left {
      @media (max-width: 767px) {
        margin-bottom: 20px;
      }
      .profile-logo {
        height: 124px;
        width: 124px;
        border-radius: 50%;
        margin-right: 27px;
        @media (max-width: 767px) {
          margin: 0 auto;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }
    .profile-logo-right {
      flex: 1;
      .profile-name-sec {
        margin-bottom: 40px;
        position: relative;
        @media (max-width: 767px) {
          text-align: center;
        }
        .profile-name {
          font-size: 23px;
          font-weight: 700;
          color: #fff;
          margin-bottom: 9px;
          @media (max-width: 767px) {
            font-size: 20px;
            margin-bottom: 6px;
          }
        }
        .profile-subname {
          font-size: 16px;
          font-weight: 400;
          color: #aaabae;
        }
        .profile-name-btn {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
      .profile-email-sec {
        display: flex;
        @media (max-width: 767px) {
          flex-direction: column;
        }
        .details-sec-data {
          width: 33.33%;
          @media (max-width: 767px) {
            width: 100%;
          }
        }
      }
    }
  }
  .profile-details-sec {
    .profile-details-heading {
      font-size: 13.5px;
      font-weight: 400;
      line-height: 25px;
      color: #aaabae;
      margin-bottom: 22px;
      @media (max-width: 767px) {
        font-size: 13px;
        line-height: 28px;
        margin-bottom: 20px;
      }
    }
    .details-sec {
      display: flex;
      @media (max-width: 767px) {
        flex-direction: column;
      }
      .details-sec-data {
        width: 33.33%;
        @media (max-width: 767px) {
          width: 100%;
        }
      }
    }
  }
}
.details-sec-data {
  @media (max-width: 767px) {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .details-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #aaabae;
    margin-bottom: 7px;
    @media (max-width: 767px) {
      line-height: 18px;
      margin-bottom: 6px;
    }
  }
  .details-data {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    line-height: normal;
    overflow-wrap: break-word;
    padding-right: 15px;
    @media (max-width: 767px) {
      line-height: 21px;
      padding-right: 0;
    }
  }
}
.my-account-profile-btn {
  display: none;
  @media (max-width: 767px) {
    display: block;
    margin-top: 40px;
    .edit-profile-btn {
      width: 100% !important;
      margin-bottom: 20px;
      font-weight: 600;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.subscription-head-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 27px;
  @media (max-width: 767px) {
    margin-bottom: 30px;
  }
}
.subscription-head-cancel {
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  position: absolute;
  right: 27px;
  top: 27px;
  @media (max-width: 767px) {
    position: relative;
    right: auto;
    top: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .subscription-plan {
    text-decoration: underline;
    color: #aaabae;
    cursor: pointer;
    @media (max-width: 767px) {
      margin: 10px 0 20px 0;
    }
  }
  .subscription-history {
    text-decoration: underline;
    color: #c33;
    cursor: pointer;
    margin-left: 45px;
    @media (max-width: 767px) {
      margin-left: 0;
      margin-bottom: 10px;
    }
  }
}

.margin27Px {
  margin-bottom: 24px;
}

.subscription-payment-box {
  background-color: #303039;
  border-radius: 9px;
  padding: 27px;
  .subscription-payment-top {
    padding-bottom: 31px;
    margin-bottom: 27px;
    border-bottom: 1px dashed rgba(123, 123, 123, 0.5);
    display: flex;
    justify-content: space-between;
    @media (max-width: 767px) {
      flex-direction: column;
      padding-bottom: 36px;
      margin-bottom: 28px;
    }
    .payment-left {
      .payment-name {
        font-size: 19.5px;
        font-weight: 400;
        color: #c9c9c9;
        margin-bottom: 19px;
        text-transform: capitalize;
        @media (max-width: 767px) {
          font-size: 15px;
          margin-bottom: 10px;
        }
      }
      .payment-price {
        font-size: 35.5px;
        font-weight: 700;
        color: #fff;
        @media (max-width: 767px) {
          font-size: 24px;
          margin-bottom: 28px;
        }
      }
    }
    .payment-right {
      text-align: right;
      @media (max-width: 767px) {
        text-align: left;
      }
      .payment-due {
        font-size: 15px;
        font-weight: 400;
        color: #aaabae;
        margin-bottom: 9px;
        @media (max-width: 767px) {
          font-size: 13px;
          margin-bottom: 10px;
        }
      }
      .payment-date {
        font-size: 18px;
        font-weight: 600;
        color: #fff;
        line-height: 21px;
        margin-bottom: 13.5px;
        @media (max-width: 767px) {
          font-size: 15px;
          line-height: 18px;
          margin-bottom: 19px;
        }
      }
      .payment-renew {
        font-size: 14px;
        font-weight: 400;
        color: #43a047;
        font-style: italic;
        @media (max-width: 767px) {
          font-size: 13px;
        }
      }
    }
  }
  .subscription-payment-bottom {
    .payment-detail-sec {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24.5px;
      @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 30px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      .payment-detail-title {
        font-size: 18px;
        font-weight: 400;
        color: #c9c9c9;
        @media (max-width: 767px) {
          font-size: 14px;
          margin-bottom: 10px;
        }
      }
      .payment-detail-price {
        font-size: 19.5px;
        font-weight: 600;
        color: #fff;
        line-height: 21px;
        @media (max-width: 767px) {
          font-size: 20px;
          line-height: normal;
        }
      }
    }
  }
}

.bold-text {
  font-size: 36px;
  font-weight: bolder;
  text-decoration: underline;
  font-style: normal !important;
  cursor: pointer;
}
.subscription-nocard-sec {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .nocard-img {
    margin-bottom: 39px;
    @media (max-width: 767px) {
      margin-bottom: 33px;
    }
  }
  .nocard-title {
    margin-bottom: 11px;
    font-size: 21px;
    font-weight: 700;
    color: #fff;
    @media (max-width: 767px) {
      margin-bottom: 9px;
      font-size: 20px;
    }
  }
  .nocard-subtitle {
    margin-bottom: 55px;
    font-size: 16px;
    font-weight: 400;
    color: #aaabae;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
}
.no-probes-card {
  background-color: #21222D;
  padding: 89px;
  border-radius: 9px;
  margin: 0 -12px;
  @media (max-width: $medium) {
    padding: 50px 20px;
    margin: 0;
  }
  .nocard-subtitle {
    margin-bottom: 0;
    text-align: center;
  }
  .contact-to-admin {
    margin-top: 40px;
    button {
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
      width: 200px;
    }
  }
}
.box-heading {
  font-size: 19.5px;
  font-weight: 500;
  color: #7b7b7b;
  @media (max-width: 767px) {
    font-size: 18px;
  }
}
.total-assigned-probes-sec {
  background-color: #21222d;
  padding: 17px 13px 13px 13px;
  border-radius: 7px;
  margin-bottom: 44px;
  .total-assigned-row {
    .total-assigned-sec {
      text-align: center;
      border-right: 1px solid rgba(170, 171, 174, 0.3);
      &:last-child {
        border-right: unset;
      }
      .total-assigned-title {
        font-size: 14px;
        font-weight: 400;
        color: #fff;
        margin-bottom: 7px;
      }
      .total-assigned-count {
        font-size: 18px;
        font-weight: 700;
        color: #fff;
      }
    }
  }
}
.assigned-probes-seperator {
  color: rgba(170, 171, 174, 0.3);
  margin: 0 35px;
}
.probes-description-top {
  margin-top: 44px;
}
.probes-detail-topbot {
  margin-top: 0;
  margin-bottom: 30px;
  .list-group-item {
    margin-bottom: 0;
  }
}
.tank-assigned-sec {
  .tank-detail-wrapper {
    margin-top: 18px;
  }
}
.mobile-hidden {
  @media (max-width: 767px) {
    display: none;
  }
}
.mobile-show {
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
}
.top-radius-none {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.bottom-radius-none {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

// profile Edit
.edit-prifile-wrapper {
  position: relative;
  .header-right-top-sec {
    position: absolute;
    top: -70px;
    right: 0px;
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
    }
    button {
      width: 124px;
      @media (max-width: 767px) {
        width: 100%;
        &:first-child {
          margin-bottom: 20px;
        }
      }
    }
    @media (max-width: 767px) {
      position: relative;
      top: auto;
      right: auto;
    }
  }
  .prifile-bottom-radius-none {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    @media (max-width: 767px) {
      border-radius: 14px;
      margin-bottom: 20px;
    }
  }
  .prifile-top-radius-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    @media (max-width: 767px) {
      border-radius: 14px;
      margin-bottom: 30px;
    }
  }
  .logo-img-sec {
    margin-bottom: 27px;
    @media (max-width: 480px) {
      margin-bottom: 35px;
    }
    .logo-img {
      position: relative;
      width: 124px;
      height: 124px;
      margin: 0 auto;
      @media (max-width: 480px) {
        width: 94px;
        height: 94px;
      }
      .edit-img-profile {
        width: 124px;
        height: 124px;
        border-radius: 50%;
        @media (max-width: 480px) {
          width: 94px;
          height: 94px;
        }
      }
      .logo-edit-img {
        position: absolute;
        background-color: #303039;
        border: 1.5px solid #21222d;
        width: 39px;
        height: 39px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        right: -7px;
        bottom: 9px;
        @media (max-width: 480px) {
          width: 34px;
          height: 34px;
        }
        .custom-input-file {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          opacity: 0;
          cursor: pointer;
          font-size: 0;
        }
      }
    }
  }
  .edit-prifile-sec {
    .profile-logo-sec {
      text-align: center;
      @media (max-width: 767px) {
        margin-bottom: 20px;
      }
      .logo-size {
        font-size: 13px;
        font-weight: 400;
        color: #9fa4ab;
        line-height: 23px;
        margin-bottom: 17px;
      }
      .logo-format {
        font-size: 13px;
        font-weight: 400;
        color: #9fa4ab;
        margin-bottom: 10px;
      }
    }
  }
}

.card-sec {
  &.card-gradient-01 {
    background: linear-gradient(134deg, #042843 12.22%, #726e9e 87.89%);
  }
  &.card-gradient-02 {
    background: linear-gradient(134deg, #064a7d 12.22%, #642401 87.89%);
  }
  border-radius: 9px;
  position: relative;
  padding: 24px 14px 20px 27.5px;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
  &::before {
    content: '';
    background-image: url('../../img/card-bg-image.svg');
    height: 120px;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 257px;
  }
  .card-sec-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 21px;
    .card-sec-logo {
      width: 43px;
      height: 43px;
      img {
        width: 80px;
        height: auto;
        position: relative;
        top: -3px;
        left: -5px;
      }
    }
    .card-sec-slider {
      display: flex;
      align-items: center;
      .card-slider {
        margin-right: 25px;
        .switch-main {
          @media (max-width: $medium) {
            position: relative;
            top: auto;
            right: -20px;
          }
        }
        .switch-main-text {
          font-size: 13px;
          font-weight: 400;
          color: #fff;
        }
      }
      .card-more {
        .icon-three-dot {
          width: 21px;
          height: 21px;
          border: unset;
          margin-left: 0;
          @media (max-width: $medium) {
            position: relative;
            top: auto;
            right: auto;
          }
          .dropdown-toggle {
            height: 21px;
            min-width: 21px;
          }
          .dropdown-menu {
            right: 0!important;
          }
        }
      }
    }
  }
  .card-sec-bottom {
    .card-num {
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 1.5px;
      color: #fff;
      margin-bottom: 18px;
    }
    .card-label {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: #aaabae;
      margin-bottom: 3.5px;
    }
    .card-desc {
      font-size: 14px;
      font-weight: 600;
      color: #fff;
      margin-bottom: 18px;
      line-height: 18px;
    }
  }
}
.user-dashboard-card-wrapper {
  .bg-left {
    background-size: 200px 200px !important;
    @media (min-width: $small-medium) and (max-width: $medium) {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
  .bg-right {
    background-size: 200px 200px !important;
    background-position-x: 115% !important;
  }
  .card-right {
    padding: 26px 35px 42px 35px !important;
    @media (min-width: $small-medium) and (max-width: $medium) {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
  .card-data-subsec {
    margin-right: 41px;
    @media (min-width: $extra-extra-large) {
      margin-right: 80px;
    }
    .card-num {
      color: #0F101A; 
    }
    .card-total {
      color: #0F101A;  
    }
  }

}
.input-group-text{
  cursor: pointer;
  background: #303039;
  border: transparent;
  color: #fff;
}
// dashboard filter css
.tabular-data-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: $medium) {
    display: unset;
  }
  .tabular-data-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
    color: #7B7B7B;
  }
  .tabular-data-filter {
    .filter-by-sec {
      display: flex;
      align-items: center;
      @media (max-width: $medium) {
        display: unset;
      }
      .wrap-select {
        .select__value-container {
          padding-left: 11px;
          padding-top: 3px;
        }
      }
      .filter-by-first {
        width: 188px;
        @media (max-width: $medium) {
          width: 100%;
        }
        .basic-multi-select {
          position: relative;
          top: 2px;
        }
      }
      .filter-by-second {
        width: 208px;
        margin-left: 18px;
        position: relative;
        @media (max-width: $medium) {
          width: 100%;
          margin: 20px 0;
        }
        .form-mt-space {
          position: absolute;
          top: -25px;
          @media (max-width: $medium) {
            position: unset;
          }
        }
      }
      .form-mt-space {
        @media (max-width: $medium) {
          margin: 0;
        }
      }
      .dashboard-export-dropdown {
        margin-left: 18px;
        @media (max-width: $medium) {
          margin-left: 0;
        }
        .header-user-detail {
          width: 108px;
          @media (max-width: $medium) {
            width: 100%;
          }
        }
        button {
          background-color: #CC3333 !important;
          height: 44px;
          position: relative;
          top: -3px;
          padding: 10px 17px;
          border-radius: 7px;
          @media (max-width: $medium) {
            position: unset;
            width: 100%;
            margin: 20px 0;
          }
        }
        .user-subtitle {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #fff;
          text-transform: capitalize;
        }
      }
    }
  }
}
.dashboard-card-right {
  background-color: #E5ECF6;
  position: relative;
  overflow: hidden;
  @media (max-width: $small-medium) {
    height: auto !important;
  }
  .admin-main-subtitle {
    @media (max-width: $small-medium) {
      display: unset;
    }
  }
  .card-data-subsec {
    @media (max-width: $small-medium) {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 20px;
      }
    }
  }
  .dashboard-card-right-bg {
    position: absolute;
    right: -77px;
    bottom: 25px;
    @media (max-width: $small-medium) {
      bottom: 0;
    }
  }
}

.wine-making-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #303039;
  border-radius: 9px;
  padding: 21px 27px;
  margin-top: 21px;
  @media (max-width: $small-medium) {
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .wine-making-left {
    @media (max-width: $small-medium) {
      margin-bottom: 20px;
    }
    .wine-making-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #7B7B7B;
      margin-bottom: 14px;
    }
    .wine-making-subtitle {
      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
      color: #C9C9C9;
      @media (max-width: $small-medium) {
        font-size: 16px;
      }
    }
  }
  .wine-making-right {
    button {
      font-size: 14px;
      &.disabled {
        color: rgba(255, 255, 255, 0.4) !important;
      }
    }
  }
}
.probes-each-div{
  font-size: 17px;
  line-height: 16px;
  font-weight: 400;
  color:#aaabae;
  margin-bottom: 35px;
  span{
    color: #fff;
  }
}
.admin-btn-right{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .header-primary-btn {
    min-width: 160px;
    width: auto;
    height: 44px;
    font-size: 14px;
  }
}
.winery-detail-space {
  margin-top: 53px;
}
.filter-by-second {
  .only-change-datetime { 
    input {
      background-color: #21222d;
    }
  }
}
.dashboard-card-wrapper-new {
  .dashboard-card-wrapper-new-row {
    margin: 0;
    gap: 22px;
    @media (min-width: 1250px) and (max-width: 1337px) {
      gap: 19px;
    }
  }
  .card-left-new {
    height: 321px !important;
    width: 65.9% !important;
    padding: 27px !important;
    .admin-main-title {
      display: unset !important;
      .card-data-sec {
        margin: 0 0 0 0;
        &:first-child {
          margin-bottom: 51px;
        }
      }
      .card-total {
        img {
          margin-left: 8px;
          position: relative;
          top: -1px;
        }
      }
    }
  }
  .card-left-new2 {
    width: 32% !important;
    height: 321px !important;
    background-image: unset !important;
    padding: 31px !important;
    .admin-main-subtitle {
      display: unset;
      .card-data-subsec {
        margin-right: 0;
        margin-bottom: 44px;
        .card-num {
          font-size: 28px;
          line-height: 33px;
        }
        .card-total {
          font-size: 14px;
        }
      }
    }
  }
}

.admin-tooltip {
  background-color: #21222D !important;
  max-width: 400px;
  width: 100% !important;
  border-radius: 16px !important;
  font-size: 16px !important;
  line-height: 30px;
  padding: 20px !important;
  opacity: 1 !important;
}