
#graph-tooltip {
    position: absolute;
    background-color: #6E9F20;
    z-index: 1;
    min-width: 134px;
    max-width: 200px;
    min-height: 85px;
    max-height: 150px;
    padding: 7px;
    border-radius: 9px;
}

.graph-tooltip-container {
    .graph-tooltip-first {
        &:first-child {
            margin-bottom: 7px;
        }
        .graph-tooltip-title {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #21222D;
        }
        .graph-title-ans {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #fff;
        }
    }
    .delete-icon {
        position: absolute;
        top: 7px;
        right: 7px;
    }
}