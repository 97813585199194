// Landing page header

.landing-page-header{
  // position: relative;
  .header-scoll-margin {
    margin-bottom: 100px;
  }
  .below-header-fixed {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
  }
  nav{
    background-color: #0F101A !important;
    box-shadow: 0px -1px 0px 0px #2C2D33 inset;
    padding-top: 17px;
    padding-bottom: 17px;
    height: 100px;
  }
  .dropdown-signin-sec{
    display: flex;
    @media (max-width: $medium) {
      position: absolute;
      top: 33px;
      right: 44px;
    }
    @media (min-width: $small-medium) and (max-width: $medium) {
      right: 80px;
    }
    .dropdown-menu {
      top: 52px;
      right: 17px;
      @media (min-width: $small-medium) and (max-width: $medium) {
        top: 36px;
        right: 8px;
      }
      .dropdown-item {
        font-size: 15px;
      }
    }
  }
  .navbar-toggler{
    border: 0;
    box-shadow: none;
    @media (max-width: $medium) {
      padding: 0;
    }
    &:focus{
      border: 0;
      box-shadow: none;
      outline: unset;
    }
    .navbar-toggler-icon {
      background-image: url('../../icons/menu.svg');
    }
  }
  .dropdown-list-btn{
    padding: 16px 21px;
    background: transparent;
    border: 1px solid #c33 !important;
    color: #FFF;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase !important;
    outline: 0;
    margin-right: 20px;
    @media (min-width: $small-medium) and (max-width: $medium) {
      margin-right: 10px;
    }
    &:hover,
    &:focus,
    &:active{
      background: transparent;
      border: 1px solid #c33;
    }
    &::after{
      display: none;
    }
    .dropdown-img{
      margin-left: 10px;
    }
    @media (max-width: $medium){
      font-size: 12px;
      width: 161px;
      height: 34px;
    }
  }
  .nav-link{
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    padding: 8px 0;
    margin-right: 20px;
    color: #fff;
    @media (max-width: $medium){
      padding: 15px 20px 15px 24px;
    }
    &.active, &:hover{
      a {
        color: #cc3333;
        font-weight: 700;
      }
    }
    a {
      color: #fff;
    }
  }
}
.btn-signin{
  height: 50px;
  width: 140px;
  font-size: 15px;
  background-color: #cc3333;
  color: $white;
  outline: 0;
  line-height: 16px;
  font-weight: 600;
  border-radius: 7px;
  border: solid 1px transparent;
  text-transform: uppercase;
  box-shadow: 0px 7.111111164093018px 14.222222328186035px 0px rgba(204, 51, 51, 0.25);
  @media (max-width: $medium) {
    font-size: 14px;
  }
  &:hover {
    background-color: #b42222;
    border: solid 1px transparent;
  }
  &:active {
    background-color: #b42222 !important;
    border: solid 1px transparent !important;
    outline: 0;
    box-shadow: none;
  }
}
.navbar-brand{
  img{
    height: 55px;
    width: auto;
    @media (max-width: $medium) {
      width: 86px;
    }
  }
}
.for-desktop-ipad {
  display: block;
  @media (max-width: $medium) {
    display: none;
  }
}
.navbar-collapse{
  @media (max-width: $medium) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000000b3;
    padding: 20px 0 15px;
    z-index: 99;
    .navbar-nav{
      width: 240px;
      background: #21222D;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      padding-top: 110px;
      @media (min-width: $small-medium) and (max-width: $medium) {
        padding-top: 60px;
      }
    }
    .btn-signin-mobile{
      position: fixed;
      bottom: 30px;
      right: 20px;
      .btn-signin{
        width: 200px;
      }
    }
  }
}
.nav-bar-close{
  display: none;
  cursor: pointer;
  @media (max-width: $small){
    display: block;
    position: absolute;
    right: 17px;
    top: 70px;
  }
  @media (min-width: $small-medium) and (max-width: $medium) {
    top: 40px;
    display: block;
    position: absolute;
    right: 17px;
  }
}

// Landing page body

.landing-page-body{
  background-image: url('../../img/plan-right-line.png');
  background-repeat: no-repeat;
  background-position: 100% 75%;
  @media (max-width: 1150px){
    background-image: unset;
  }
  .pricing-sec {
    background-image: url('../../img/plan-left-line.png');
    background-repeat: no-repeat;
    background-position: -4px -86px;
    margin: 0;
    padding-top: 98px;
    @media (max-width: $small-medium) {
      padding-top: 55px;
      background-position: -11px -11px;
      background-size: 29% 34%;
    }
    @media (min-width: $small-medium) and (max-width: $medium) {
      padding-top: 70px;
      background-position: -11px -46px;
      background-size: 25% 52%;
    }
  }
  .pricing-wrap {
    background-image: unset;
    margin: 0 0 14px 0;
    padding: 0 135px;
    height: 231px;
    @media (max-width: $small-medium) {
      padding: 0 20px;
      height: auto;
    }
    @media (min-width: $small-medium) and (max-width: $medium) {
      padding: 0 60px;
      height: auto;
    }
    .pricing-subtitle {
      margin-top: 0;
      @media (max-width: $small-medium) {
        font-size: 22px;
        line-height: 36px;
      }
      .pricing-subtitle {
        h3 {
          font-size: 48px;
        }
      }
    }
    .pricing-desc {
      font-size: 18px;
      line-height: 32px;
      @media (max-width: $small-medium) {
        margin-bottom: 74px;
        padding: 0;
        font-size: 14px;
        line-height: 26px;
      }
      @media (min-width: $small-medium) and (max-width: 1150px) {
        font-size: 16px;
      }
    }
    .discount-sec {
      @media (max-width: $small-medium) {
        right: 203px;
        top: 14px;
      }
    }
  }
  .edit-email-package-left {
    .edit-package-left-title {
      margin: 0;
      @media (max-width: $small-medium) {
        font-size: 15px !important;
        margin: 0 0 14px 0 !important;
      }
      @media (min-width: $small-medium) and (max-width: 1150px) {
        font-size: 18px;
      }
    }
    .subprobs-dollar {
      margin-top: 15px;
    }
    .subprobs-dollar-first {
      margin-top: 21px;
    }
  }
  .above-addon {
    border-radius: 25px;
    background: #21222D;
    padding: 40px;
    margin: 0 135px;
    @media (max-width: $small-medium) {
      margin: 60px 20px 0 20px;
      padding: 25px;
    }
    @media (min-width: $small-medium) and (max-width: $medium) {
      margin: 40px 55px 0 55px;
    }
    .above-addon-btn {
      width: 220px;
      @media (max-width: $small-medium) {
        width: 100%;
      }
    }
  }
  .landing-add-on-title {
    color: #AAABAE;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    font-family: 'Caveat', sans-serif;
    margin: 40px 135px 20px;
    @media (max-width: $small-medium) {
      margin: 40px 18px 20px;
    }
    @media (min-width: $small-medium) and (max-width: $medium) {
      margin: 40px 80px 20px;
    }
  }
  .edit-package-left-title {
    font-size: 22px;
    margin: 0 0 21px 0;
  }
  .edit-package2 {
    font-size: 18px;
    line-height: 21px;
  }
  .subprobs-dollar {
    font-size: 48px;
    line-height: normal;
    margin-left: 0;
    margin-top: 21px;
  }
  .subprobs-dollar2 {
    font-size: 36px;
    line-height: 42px;
  }
  .default-month {
    font-size: 20px;
    line-height: normal;
  }
  .default-month2 {
    font-size: 18px;
    line-height: normal;
  }
  .addon-sec {
    background: #21222D;
    margin: 0 135px;
    padding: 40px;
    @media (max-width: $small-medium) {
      margin: 0 20px;
      padding: 25px;
    }
    @media (min-width: $small-medium) and (max-width: $medium) {
      margin: 0 55px;
    }
    .addon-des {
      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .addon-des {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 35px !important;
    @media (max-width: $small-medium) {
      margin-top: 25px !important;
    }
    p {
      font-size: 18px;
      @media (max-width: $small-medium) {
        font-size: 14px !important;
      }
    }
  }
  .custom-mark {
    width: 88px;
    top: -51px !important;
    right: 93px !important;
    @media (max-width: $small-medium) {
      top: -63px !important;
      left: 154px !important;
      width: auto;
      height: 41px !important;
      margin: 0 20px;
      transform: rotate(-30deg) !important;
    }
    @media (min-width: $small-medium) and (max-width: $medium) {
      top: -63px !important;
      left: 192px !important;
      width: 59px !important;
      height: 62px !important;
    }
  }
  .switch-wrap {
    .tab {
      margin-bottom: 0 !important;
    }
  }
}


// Landing page Pre Footer

.landing-pre-footer{
  padding: 92px 0 92px 135px;
  background-color: #21222D;
  position: relative;
  overflow: hidden;
  @media (max-width: $small){
    padding: 72px 0 203px 20px;
  }
  @media (min-width: $small-medium) and (max-width: 1100px) {
    padding: 72px 0 203px 55px;
  }
  &::before{
    content:url('../../img/pre-footer-ellipse.svg');
    position:absolute;
    left:0;
    top:0;
    @media (max-width: $small-medium){
      left: 0;
      top: -69px;
    }
  }
  &::after{
    content: '';
    background:url('../../img/pre-footer-img.png');
    background-repeat: no-repeat;
    position:absolute;
    right:-7px;
    bottom:0;
    height: 386px;
    width: 670px;
    @media (max-width: $medium){
      height: 335px;
      width: 644px;
    }
    @media (max-width: $small-medium){
      right: 0;
      bottom: -56px;
    }
    @media (max-width: 575px){
      right: 0px;
      bottom: 0;
      width: 80%;
      background-size: 520px auto;
      height: 300px;
    }
  }
  .pre-footer-sec{
    width: 45%;
    position: relative;
    z-index: 1;
    &::before{
      content:url('../../img/lets-bg.svg');
      position:absolute;
      right: -32%;
      top: -56px;
      @media (max-width: 1150px){
        display: none;
      }
    }
    @media (max-width: $medium){
      width: 100%;
    }
    .pre-footer-title{
      color: #FFF;
      font-size: 30px;
      font-weight: 700;
      line-height: 48px;
      margin-bottom: 17px;
      @media (max-width: $medium){
        font-size: 20px;
        margin-bottom: 13px;
        line-height: normal;
      }
    }
    .pre-footer-subtitle{
      color: #C9C9C9;
      font-size: 18px;
      font-weight: 400;
      line-height: 32px;
      margin-bottom: 50px;
      @media (max-width: $medium){
        font-size: 14px;
        margin-bottom: 30px;
        line-height: 26px;
      }
    }
  }
}
.pre-footer-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 55px;
  font-size: 16px;
  @media (max-width: $medium){
    width: 180px;
    height: 45px;
    font-size: 14px;
    font-weight: 600;
  }
}

// Landing page Footer

.landing-page-footer{
  background-color: #08080D;
  .footer-container {
    padding: 0 135px;
    @media (max-width: $medium){
      flex-direction: column;
      padding: 0 20px;
    }
  }
  .landing-footer-top{
    text-align: center;
    padding: 31px 0;
    border-bottom: 1px dashed rgba(123, 123, 123, 0.5);
    img{
      height: 55px;
      width: auto;
      @media (max-width: $medium){
        height: 35px;
      }
    }
  }
  .landing-footer-bottom{
    padding: 33px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: $small-medium){
      flex-direction: column;
      padding: 20px 0 30px 0;
    }
    @media (min-width: $small-medium) and (max-width: 1100px) {
      flex-direction: unset;
    }
    .landing-footer-left{
      font-size: 15px;
      line-height: 24px;
      font-weight: 400;
      color: #fff;
      @media (max-width: $medium){
        text-align: center;
        margin-bottom: 25px;
        font-size: 13px;
      }
    }
    .landing-footer-right{
      .social-logo{
        margin-left: 20px;
        width: 25px;
        height: 25px;
        background-color: #fff;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover {
          background-color: #CC3333;
          svg {
            path {
              fill: #fff;
            }
          }
        }
        &:first-child{
          margin-left: 0;
        }
        img {
          cursor: pointer;
        }
        svg {
          cursor: pointer;
        }
      }
    }
  }
}
.our-customer-container {
  padding: 95px 0;
  background-image: url('../../img/plan-shadow.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0 -202px;
  @media (max-width: $small-medium){
    padding: 32px 0 20px 0;
  }
  @media (min-width: $small-medium) and (max-width: $medium) {
    padding: 60px 0;
  }
  .customer-heading {
    margin-bottom: 80px;
    position: relative;
    @media (max-width: $small-medium){
      margin-bottom: 10px;
    }
    @media (min-width: $small-medium) and (max-width: 1100px) {
      padding: 0 80px;
      margin-bottom: 40px;
    }
    .customer-arrow {
      position: absolute;
      left: 150px;
      bottom: -33px;
      @media (max-width: $small-medium){
        display: none;
      }
      @media (min-width: $small-medium) and (max-width: 1100px) {
        left: 80px;
        width: 100px;
      }
    }
    .customer-title {
      color: #FFF;
      text-align: center;
      font-family: 'Roboto', sans-serif;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
      @media (max-width: $small-medium){
        font-size: 22px;
        line-height: 36px;
        padding: 0 65px;
      }
      @media (min-width: $small-medium) and (max-width: 1100px) {
        font-size: 32px;
      }
    }
    .customer-subtitle {
      color: #C9C9C9;
      text-align: center;
      font-family: 'Roboto', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      @media (max-width: $small-medium){
        font-size: 14px;
        line-height: 26px;
        padding: 18px 20px 0 20px;
      }
      @media (min-width: $small-medium) and (max-width: 1100px) {
        font-size: 16px;
        max-width: 400px;
        margin: auto;
        line-height: 23px;
        margin-top: 8px;
      }
    }
  }
}

.ad-slider-multiple {
  max-width: 1170px;
  width: 100%;
  margin: auto;
  .slick-list {
    margin: 0 60px;
    @media (max-width: 576px){
      margin: 0;
    }
    .slick-slide {
      padding: 0 20px;
      text-align: center;
      @media (max-width: 576px){
        padding: 0 20px;
      }
    }
  }
  .slick-prev {
      bottom: 0;
      top: -25px;
      left: 0;
      margin: auto;
      z-index: 1;
      @media (min-width: $small-medium) and (max-width: $medium) {
        left: 56px;
      }
      @media (min-width: 920px) and (max-width: 1024px) {
        left: 96px;
      }
      @media (min-width: 1025px) and (max-width: 1110px) {
        left: 10px;
      }
      &::before {
        content: url('../../icons/arrow-white.svg') !important;
        font-family: unset !important;
        position: absolute;
        width: 49px;
        height: 49px;
        border-radius: 50%;
        border: 0;
        background-color: #21222D;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 1 !important;
        line-height: 0;
        color: #fff !important;
        fill: #fff;
        transform: rotate(90deg);
          @media (max-width: 576px){
            display: none !important;
          }
      }
  }
  .slick-next {
      left: unset !important;
      top: -25px;
      right: 29px;
      bottom: 0;
      margin: auto;
      @media (min-width: $small-medium) and (max-width: $medium) {
        right: 86px;
      }
      @media (min-width: 920px) and (max-width: 1024px) {
        right: 136px;
      }
      @media (min-width: 1025px) and (max-width: 1110px) {
        right: 39px;
      }
      &::before {
        content: url('../../icons/arrow-white.svg') !important;
          font-family: unset !important;
          position: absolute;
          width: 49px;
          height: 49px;
          border-radius: 50%;
          border: 0;
          background-color: #21222D;
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 1 !important;
          transform: rotate(270deg);
          @media (max-width: 576px){
            display: none !important;
          }
      }
  }
  .slick-dots {
    display: none !important;
  }
}
.testy-item-card {
  border-radius: 25px;
  border: 1px solid #7B7B7B;
  background: transparent;
  width: 486px !important;
  padding: 40px;
  text-align: left;
  // @media (max-width: 576px){
  //   width: 100% !important;
  //   padding: 25px;
  // }
  @media (max-width: $small-medium){
    font-size: 14px;
    line-height: 24px;
    margin: 30px 0;
    width: 100% !important;
    padding: 25px;
  }
  @media (min-width: $medium) and (max-width: 1110px) {
    width: 453px !important;
  }
  p {
    color:  #C9C9C9;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    margin: 30px 0;
    @media (max-width: $small-medium){
      font-size: 14px;
      line-height: 24px;
      margin: 26px 0;
    }
    @media (min-width: $small-medium) and (max-width: 1110px) {
      font-size: 16px;
    }
  }
  h6 {
    color: #FFF;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media (max-width: $small-medium){
      font-size: 13px;
    }
  }
  .quote {
    @media (max-width: $small-medium){
      width: 35px;
    }
  }
}

// praja scss
.inthe-dark-wrapper {
  padding-top: 105px;
  padding-bottom: 97px;
  background-image: url('../../img/ellipse-red.svg');
  background-repeat: no-repeat;
  background-size: 42% 74%;
  background-position: 0 50px;
  position: relative;
  overflow: hidden;
  @media (max-width: $small-medium){
    display: block;
    padding-top: 44px;
    background-image: unset;
  }
  @media (min-width: $medium) and (max-width: 1110px) {
    padding-bottom: 70px;
  }
  &::before{
    content: '';
    background-image: url('../../img/inthedark-shadow.png');
    position:absolute;
    left: 0;
    right:0;
    bottom:0;
    height: 400px;
    z-index: 11;
  }
  &::after{
    content: '';
    background:url('../../img/inthedark-right.png');
    background-repeat: no-repeat;
    position:absolute;
    right: -73px;
    bottom: -12%;
    height: 892px;
    width: 597px;
    z-index: 1;
    @media (max-width: $small-medium){
      display: none;
    }
  }
  .bg-image-inthe{
    background-image: url('../../img/ellipse-green.svg');
    background-repeat: no-repeat;
    background-size: 408px 659px;
    background-position: right bottom;
  }
  .inthe-container {
    padding: 0 130px;
    @media (max-width: $small-medium){
      padding: 0 20px;
      background-image: unset;
    }
    @media (min-width: $small-medium) and (max-width: 1150px) {
      padding: 0 60px;
    }
  }
  .heading-title-sec{
    position: relative;
    text-align: center;
    .heading{
      font-size: 54px;
      font-weight: 600;
      color: #fff;
      margin-bottom: 63px;
      position: relative;
      display: inline-block;
      @media (max-width: $small-medium){
        font-size: 28px;
        line-height: 42px;
        margin-bottom: 34px;
      }
      @media (min-width: $small-medium) and (max-width: 1150px) {
        font-size: 32px;
      }
      span {
        @media (max-width: $small-medium){
          display: block;
        }
      }
    }
    .heading-line{
      position: absolute;
      bottom: -23px;
      right: 0;
      @media (max-width: $small-medium){
        bottom: -10px;
        width: 223px;
      }
      @media (min-width: $small-medium) and (max-width: 1150px) {
        bottom: -17px;
        width: 357px;
      }
    }
  }
  .subheading{
    text-align: center;
    color: #C9C9C9;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    max-width: 850px;
    margin: auto auto 36px auto;
    @media (max-width: $small-medium){
      font-size: 16px;
      line-height: 28px;
      margin: auto auto 40px auto;
    }
  }
  .inthedark-btn-sec{
    text-align: center;
    margin-bottom: 64px;
    .inthedark-btn{
      width: 220px;
      height: 55px;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      position: relative;
      z-index: 99;
      @media (min-width: $small-medium) and (max-width: 1150px) {
        width: 180px;
      }
      img {
        margin-left: 3px;
      }
    }
  }
  .inthe-dark-img-sec{
    position: relative;
    text-align: center;
    .inthedarkright3 {
      position: relative;
      z-index: 9;
    }
    .inthedarkleft{
      position: absolute;
      left: -57px;
      top: 26%;
      z-index: 10;
      animation: mymove 8s infinite;
      margin-top: 195px;
      @media (max-width: $small-medium){
        width: 115px;
        margin-top: 46px;
        left: -8px;
      }
      @media (min-width: $small-medium) and (max-width: $medium) {
        width: 280px;
        margin-top: 95px;
        left: -17px;
      }
      @media (min-width: $medium) and (max-width: 1150px) {
        width: 230px;
        left: -22px;
        margin-top: 95px;
      }
      @media (min-width: 1200px){
        width: 400px;
      }
      @media (min-width: 1400px){
        width: 465px;
      }
      @media (min-width: 1500px){
        width: 500px;
      }
      @media (min-width: 1700px){
        width: 550px;
      }
      @media (min-width: 1900px){
        width: 650px;
      }
      @media (min-width: 2100px){
        width: 700px;
      }
      @media (min-width: 2200px){
        width: 750px;
      }
      @media (min-width: 2500px){
        width: 820px;
      }
      @media (min-width: 3000px){
        width: 950px;
      }
      @media (min-width: 3640px){
        width: 1250px;
      }
    }
    .inthedarkright{
      position: absolute;
      right: -50px;
      bottom: -7px;
      z-index: 11;
      margin-top: 330px;
      animation: mymove2 8s infinite;
      @media (max-width: $small-medium){
        width: 129px;
        right: -6px;
        margin-top: 60px;
      }
      @media (min-width: $small-medium) and (max-width: $medium) {
        width: 280px;
        right: -15px;
        margin-top: 200px;
      }
      @media (min-width: $medium) and (max-width: 1150px) {
        width: 245px;
        right: -20px;
        margin-top: 250px;
      }
      @media (min-width: 1200px){
        width: 400px;
      }
      @media (min-width: 1400px){
        width: 465px;
      }
      @media (min-width: 1500px){
        width: 500px;
      }
      @media (min-width: 1700px){
        width: 550px;
      }
      @media (min-width: 1900px){
        width: 650px;
      }
      @media (min-width: 2100px){
        width: 700px;
      }
      @media (min-width: 2200px){
        width: 700px;
      }
      @media (min-width: 2500px){
        width: 820px;
      }
      @media (min-width: 3000px){
        width: 950px;
      }
      @media (min-width: 3640px){
        width: 1250px;
      }
    }
  }
}

.tables-graph-wrapper{
  background: rgba(8, 8, 13, 0.5);
  padding: 77px 0 124px 0;
  background-image: url('../../img/explore-shadow.png');
  background-repeat: no-repeat;
  background-size: 100% 51%;
  background-position: 0 0;
  @media (max-width: $small-medium){
    padding: 40px 0 38px 0;
  }
  @media (min-width: $medium) and (max-width: 1150px) {
    padding: 77px 0 80px 0;
  }
  .tables-graph-sec{
    position: relative;
    .tables-graph-heading-sec{
      position: relative;
      .tables-graph-heading{
        text-align: center;
        .heading{
          color: #FFF;
          text-align: center;
          font-size: 48px;
          font-weight: 600;
          margin-bottom: 25px;
          position: relative;
          display: inline-block;
          @media (max-width: $small-medium){
            font-size: 22px;
            line-height: 36px;
          }
          @media (min-width: $small-medium) and (max-width: 1100px) {
            font-size: 32px;
          }
          span {
            @media (max-width: $small-medium){
              display: block;
            }
          }
          .tables-graph-img{
            position: absolute;
            right: -23px;
            top: -78px;
            @media (max-width: $small-medium){
              top: -42px;
              width: 74%;
              right: 32px;
            }
            @media (min-width: $small-medium) and (max-width: 1100px) {
              right: -27px;
              top: -55px;
              width: 44%;
            }
          }
        }
      }
      .tables-graph-arrow{
        position: absolute;
        top: 37px;
        left: -22px;
        @media (max-width: 1150px){
          padding: 0 20px;
          display: none;
        }
      }
      .tables-graph-subheading{
        color: #C9C9C9;
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        line-height: 32px;
        margin: auto auto 60px auto;
        max-width: 986px;
        width: 100%;
        @media (max-width: $small-medium){
          font-size: 14px;
          line-height: 26px;
        }
        @media (min-width: $small-medium) and (max-width: 1100px) {
          font-size: 16px;
          line-height: 28px;
          margin-bottom: 50px;
        }
      }
    }
    .tables-graph-data-row{
      gap: 33px;
      @media (max-width: $small-medium){
        padding: 0 20px;
      }
      @media (min-width: $small-medium) and (max-width: 1100px) {
        gap: 20px;
      }
      .tables-graph-data{
        width: 31.33%;
        @media (max-width: $small-medium){
          width: 100%;
          height: 485px;
        }
        @media (min-width: $small-medium) and (max-width: 1100px) {
          width: 31.33%;
          height: 348px;
        }
        &.first-sec{
          position: relative;
          background: #21222D;
          padding: 47px 30px 0 30px;
          @media (min-width: $small-medium) and (max-width: 1100px) {
            padding: 30px 20px 0 20px;
          }
          .msp-data-img-sec{
            position: absolute;
            bottom: 0;
            left: 20px;
            right: 20px;
            img{
              width: 100%;
            }
          }
        }
        &.second-sec{
          background: #C33;
          text-align: center;
          padding: 34px 33px 0 33px;
          @media (min-width: $small-medium) and (max-width: 1100px) {
            padding: 30px 20px 0 20px;
          }
          .probe-data-img{
            width: 100%;
            @media (max-width: 1100px){
              height: 100%;
            }
          }
        }
        &.third-sec{
          background: #21222D;
          position: relative;
          padding-top: 0;
          @media (max-width: $medium){
            padding-top: 0;
          }
          .third-sec-data{
            padding: 0 30px 40px 30px;
            position: absolute;
            bottom: 0;
            left: 0;
            @media (min-width: $small-medium) and (max-width: 1100px) {
              padding: 0 10px 20px 10px;
            }
          }
        }
      }
    }
  }
  .tables-graph-data{
    border-radius: 25px;
    background: #21222D;
    .heading{
      color: #FFF;
      font-size: 26px;
      font-weight: 700;
      margin-bottom: 24px;
      @media (max-width: $small-medium){
        font-size: 22px;
        margin-bottom: 20px;
      }
      @media (min-width: $small-medium) and (max-width: 1100px) {
        font-size: 20px;
        margin-bottom: 18px;
      }
    }
    .subheading{
      color: #AAABAE;
      font-size: 17px;
      font-weight: 400;
      line-height: 30px;
      @media (max-width: $small-medium){
        font-size: 14px;
        line-height: 25px;
      }
      @media (min-width: $small-medium) and (max-width: 1100px) {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}


// saurabh css
.partner-wrapper{
  background: #21222D;
  .partner-sec{
    padding: 45px 0;
    @media (max-width: $small-medium){
      padding: 31px 0;
    }
    .heading{
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      color: #fff;
      margin-bottom: 35px;
      @media (max-width: $small-medium){
        font-size: 14px;
        margin-bottom: 27px;
      }
    }
  }
  .partner-logo-sec{
    padding: 0 70px;
    opacity: 0.5;
    @media (max-width: $small-medium){
      overflow-y: auto;
      padding: 0;
      justify-content: unset;
    }
    .slick-track {
      display: flex;
      align-items: center;
    }
    .slick-slide {
      @media (max-width: $small-medium){
        margin-right: 0;
        width: 50% !important;
      }
      @media (max-width: 1100px){
        margin-right: 0;
      }
      div {
        text-align: center;
      }
      img {
        width: auto !important;
        @media (max-width: $small-medium){
          width: 50% !important;
        }
      }
    }
    .slick-dots {
      display: none !important;
    }
    .slick-arrow {
      display: none !important;
    }
  }
}

.temperature-wrapper{
  padding: 140px 0 120px 0;
  @media (max-width: $small-medium){
    padding: 51px 0 45px 0;
  }
  @media (min-width: $small-medium) and (max-width: 1100px) {
    padding: 140px 0 80px 0;
  }
  .temperature-img-sec{
    @media (max-width: $small-medium){
      padding: 0 23px 54px 23px;
    }
    @media (min-width: $small-medium) and (max-width: 1100px) {
      margin-bottom: 40px;
    }
    .temperature-img{
      position: relative;
      .temp-left {
        @media (max-width: $small-medium){
          max-width: 82%;
          width: 100%;
        }
        @media (min-width: $small-medium) and (max-width: 1100px) {
          max-width: 65%;
          width: 100%;
        }
      }
      .temp-bottom-img{
        position: absolute;
        bottom: 0;
        right: 40px;
        @media (min-width: $small-medium) and (max-width: 1100px) {
          right: 120px;
        }
        @media (max-width: $small-medium){
          width: 200px;
        }
      }
      .temp-right-img{
        position: absolute;
        bottom: 172px;
        right: 15px;
        width: 35px;
        @media (min-width: $small-medium) and (max-width: 1100px) {
          bottom: 172px;
          right: 94px;
        }
        @media (max-width: $small-medium){
          bottom: 95px;
        }
      }
    }
  }
  .temperature-text-sec{
    padding-left: 70px;
    @media (max-width: $small-medium){
      padding: 0 20px;
    }
    @media (min-width: $small-medium) and (max-width: 1150px) {
      padding-left: 0;
    }
    .subtitle{
      color: #AAABAE;
      margin-bottom: 25px;
      font-size: 22px;
      font-weight: 500;
      line-height: 32px;
      text-transform: uppercase;
      font-family: 'Caveat',sans-serif;
      @media (max-width: $small-medium){
        font-size: 18px;
        margin-bottom: 8px;
      }
    }
    .title{
      color: #fff;
      font-size: 52px;
      font-weight: 600;
      line-height: 66px;
      margin-bottom: 15px;
      @media (max-width: $small-medium){
        font-size: 26px;
        line-height: 40px;
        margin-bottom: 20px;
      }
      @media (min-width: $small-medium) and (max-width: 1100px) {
        font-size: 31px;
        line-height: 36px;
      }
    }
    .para{
      color: #C9C9C9;
      font-size: 18px;
      font-weight: 400;
      line-height: 32px;
      margin-bottom: 35px;
      @media (max-width: $small-medium){
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 20px;
      }
      @media (min-width: $small-medium) and (max-width: 1100px) {
        font-size: 16px;
        line-height: 28px;
      }
    }
  }
}
.temperature-btn-sec{
  .temperature-arrow-btn{
    color: #C33 !important;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    background-color: transparent;
    border: 0;
  }
}
.system-data-img{
  width: 100%;
  position: absolute;
  left: 0;
  @media (max-width: 1100px){
    width: 100%;
  }
}
.system-data-img2{
  width: 100%;
  padding: 36px 10px;
  @media (max-width: $small-medium){
    width: 100%;
    top: 83px;
  }
  @media (min-width: $small-medium) and (max-width: $medium) {
    padding: 20px 0;
    width: calc(100% - 20px);
  }
  @media (min-width: $medium) and (max-width: 1100px) {
    padding: 20px 0;
    width: calc(100% - 20px);
  }
}
.policy-wrapper{
  padding: 95px 135px;
  background: #0F101A;
  position: relative;
  @media (max-width: $medium){
    padding: 40px 20px;
  }
  &::before{
    content: " ";
    background-image: url('../../img/policycirc-bg.png');
    position: absolute;
    background-repeat: no-repeat;
    width: 639px;
    height: 100%;
    right: 0;
    top: -148px;
    @media (max-width: $medium){
      width: 447px;
      top: -276px;
    }
  }
  &.about-bg-center{
    background-image: url('../../img/aboutus-bgcenter.png');
    background-repeat: no-repeat;
    background-size: 100% 26%;
    background-position: 0 1400px;
    @media (max-width: $medium){
      background-position: 0 1000px;
    }
  }
  .policy-sec{
    position: relative;
    h1.mainheading{
      color: #fff;
      font-size: 54px;
      font-weight: 600;
      margin-bottom: 50px;
      @media (max-width: $medium){
        font-size: 28px;
        margin-bottom: 30px;
      }
    }
    p{
      &.para{
        color: #C9C9C9;
        font-size: 18px;
        font-weight: 400;
        line-height: 36px;
        margin-bottom: 40px;
        @media (max-width: $medium){
          font-size: 14px;
          line-height: 30px;
          margin-bottom: 30px;
        }
      }
      &.mb-10{
        margin-bottom: 20px;
      }
      &.mb-20{
        margin-bottom: 20px;
      }
      &.mb-30{
        margin-bottom: 30px;
      }
      &.indent-num{
        color: #C9C9C9;
        font-size: 18px;
        font-weight: 400;
        line-height: 46px;
        margin-bottom: 15px;
        @media (max-width: $medium){
          font-size: 14px;
          line-height: 30px;
          margin-bottom: 20px;
        }
      }
    }
    h3{
      margin: 80px 0 40px 0;
      color: #fff;
      font-size: 26px;
      font-weight: 700;
      @media (max-width: $medium){
        margin: 60px 0 20px 0;
        font-size: 20px;
      }
    }
    .unnum-list{
      margin-left: 15px;
      li{
        list-style: disc;
        color: #C9C9C9;
        font-size: 18px;
        font-weight: 400;
        line-height: 36px;
        margin-bottom: 40px;
        @media (max-width: $medium){
          font-size: 14px;
          line-height: 30px;
        }
      }
    }
    .indent-sec{
      margin-left: 50px;
      @media (max-width: $medium){
        margin-left: 40px;
      }
      .indent-num{
        margin-bottom: 10px;
        &.mb-50{
          margin-bottom: 50px;
        }
      }
    }
    a{
      word-break: break-all;
      color: #01A3EC;
    }
  }
}
.aboutus-upperimg{
  position: relative;
  img{
    width: 100%;
  }
}
.aboutus-btn-sec{
  margin-bottom: 50px;
  @media (max-width: $medium){
    margin-bottom: 40px;
  }
}
.aboutus-img-sec{
  margin: 50px 0;
  @media (max-width: $medium){
    margin: 30px 0;
  }
  @media (min-width: $small-medium) and (max-width: 1100px) {
    text-align: center;
  }
  img{
    width: 100%;
    @media (min-width: $small-medium) and (max-width: 1100px) {
      width: auto;
    }
  }
}
.contact-heading{
  margin-top: 85px;
  margin-bottom: 40px;
  color: #AAABAE;
  font-family: 'Caveat', sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  text-transform: uppercase;
  @media (max-width: $medium){
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
.contact-wrapper{
  display: flex;
  align-items: center;
  @media (max-width: $medium){
    flex-direction: column;
  }
  .contact-sec{
    display: flex;
    align-items: center;
    @media (max-width: $medium){
      width: 100%;
    }
    &:first-child{
      margin-right: 190px;
      @media (max-width: $medium){
        margin-right: 0;
        margin-bottom: 40px;
      }
    }
    .contact-left{
      margin-right: 27px;
      @media (max-width: $small-medium){
        margin-right: 23px;
      }
      img{
        @media (max-width: $small-medium){
          width: 53px;
          height: 53px;
        }
      }
    }
    .contact-right{
      .contact-title{
        color: #C9C9C9;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 14px;
        @media (max-width: $medium){
          font-size: 14px;
          margin-bottom: 11px;
        }
      }
      .contact-no{
        color: #fff;
        font-size: 24px;
        font-weight: 700;
        word-break: break-all;
        @media (max-width: $medium){
          font-size: 22px;
        }
      }
    }
  }
}
// contact us page scss
.contact-container {
  padding: 95px 135px 100px 135px;
  background-image: url('../../img/contactus-bg.svg');
  background-repeat: no-repeat;
  background-position: 100% 100%;
  @media (max-width: $small-medium) {
    padding: 50px 20px 50px 20px;
    background-position: 14% -65%;
  }
  @media (min-width: $small-medium) and (max-width: 1150px) {
    padding: 80px 40px 80px 40px;
    background-position: 228% 249%;
  }
  @media (min-width: 1921px) {
    background-position: 100% -15%;
    height: calc(100vh - 313px);
    background-size: 800px;
  }
  .contact-left {
    padding-right: 70px;
    @media (max-width: $medium) {
      padding-right: 40px;
    }
  }
  .contact-right {
    margin-top: 71px;
    @media (max-width: $small-medium) {
      margin-top: 50px;
    }
    @media (min-width: $small-medium) and (max-width: $medium) {
      margin-top: 0;
    }
    .contact-form-wrapper {
      border-radius: 25px;
      background: #21222D;
      padding: 30px;
      @media (max-width: $small-medium) {
        padding: 30px 20px;
      }
      .react-tel-input {
        input {
          padding-left: 58px !important;
          border: solid 0 #303039 !important;
          background-color: #303039 !important;
          width: 100% !important;
          box-shadow: unset !important;
          border-radius: 8px !important;
          height: 44px !important;
          &:focus {
            background-color: transparent;
            border-color: unset;
            outline: 0;
            box-shadow: none;
            color: #fff;
            border: solid 0 #303039;
          }
        }
        input[disabled] {
          opacity: 0.6;
          cursor: inherit;
        }
        .flag-dropdown {
          background-color: #303039 !important;
          border: 0 solid #303039 !important;
          border-right: 0 !important;
          border-radius: 8px 0 0 8px;
          &.open {
            border-radius: 8px;
            width: 100%;
            .selected-flag {
              background: #303039 !important;
              border-radius: 8px 0 0 8px;
              &:hover,
              &:focus {
                background: #303039 !important;
              }
              .up {
                border-top: 0 !important;
              }
            }
          }
          .selected-flag {
            &:hover,
            &:focus {
              background-color: #303039 !important;
              border-radius: 8px 0 0 8px;
            }
          }
        }
        .country-list {
          background-color: #393a42 !important;
          color: #fff;
          border-radius: 7px;
          width: 100%;
          margin: 3px 0 0 0;
          li {
            &:hover {
              background-color: #4a4b50 !important;
            }
            &[aria-selected='true'] {
              background-color: #4a4b50 !important;
            }
          }
          .country {
            padding: 8px 15px 7px 15px;
            height: 51px;
            border-bottom: 1px solid #4a4b50;
            display: flex;
            align-items: center;
            &:last-child {
              border-bottom: 0;
            }
          }
        }
        .selected-flag {
          .flag {
            left: 15px !important;
          }
          .arrow {
            margin-top: -3px !important;
            left: 21px !important;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid #aaabae;
          }
          .arrow.up {
            border-bottom: 6px solid #aaabae;
          }
        }
      }
    }

  }
  .contact-title {
    color: #FFF;
    font-size: 54px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    @media (max-width: $small-medium) {
      font-size: 28px;
      line-height: 42px;
    }
    @media (min-width: $small-medium) and (max-width: $medium) {
      font-size: 36px;
      line-height: 32px;
    }
  }
  .contact-subtitle {
    color: #C9C9C9;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    margin: 20px 0 60px;
    @media (max-width: $small-medium) {
      font-size: 16px;
      line-height: 28px;
      margin: 8px 0 40px;
    }
    @media (min-width: $small-medium) and (max-width: $medium) {
      font-size: 15px;
      line-height: 28px;
      margin: 16px 0 50px;
    }
  }
  .contact-btn {
    margin-top: 40px;
    @media (max-width: $small-medium) {
      margin-top: 30px;
    }
    button {
      width: 200px;
      @media (max-width: $small-medium) {
        width: 100%;
      }
    }
  }
  .form-control {
    height: 44px;
    margin: 0;
    padding: 11px 32px 10px 16px;
    border-radius: 7px;
    border: 0;
    background-color: #303039;
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 400;
    box-shadow: none;
    transition: none !important;
    line-height: 22px;
    @media (max-width: $medium) {
      font-size: 14px;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #303039 inset !important;
      -webkit-text-fill-color: #fff !important;
      font-size: 14px;
    }
    &:hover,
    &:focus {
      -webkit-box-shadow: 0 0 0 30px #303039 inset !important;
      color: $white !important;
      font-size: 14px;
      background-color: #303039;
      border: 0;
      @media (max-width: $medium) {
        font-size: 14px;
      }
    }
    &::placeholder {
      color: $grey;
      font-size: 14px;
      @media (max-width: $medium) {
        font-size: 14px;
      }
    }
  }
  textarea {
    height: auto !important;
    background-color: #303039;
    resize: none;
    overflow: hidden;
    &:hover,
    &:focus {
      background-color: #303039;
      border-radius: 7px;
    }
  }
}
.contact-infor {
  .contact-infor-title {
    color: #AAABAE;
    font-family: 'Caveat',sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    text-transform: uppercase;
    margin-bottom: 45px;
    @media (max-width: $small-medium) {
      font-size: 18px;
      margin-bottom: 30px;
    }
    @media (min-width: $small-medium) and (max-width: $medium) {
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      margin-bottom: 40px;
    }
  }
  .contact-infor-wrapper {
    display: flex;
    align-items: center;
    &.contact-infor-ist {
      margin-bottom: 40px;
      @media (max-width: $small-medium) {
        margin-bottom: 30px;
      }
    }
    .contact-infor-left {
      background-color: #21222D;
      width: 62px;
      height: 62px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 27px;
      @media (max-width: $small-medium) {
        width: 53px;
        height: 53px;
        margin-right: 23px;
      }
    } 
    .contact-infor-right {
      .phone-text {
        color: #C9C9C9;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 10px;
        @media (max-width: $small-medium) {
          font-size: 14px;
          margin-bottom: 8px;
        }
      }
      .phone-number {
        color: #fff;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        @media (max-width: $small-medium) {
          font-size: 20px;
        }
      }
    }
  }
}
.landing-page-container {
  padding: 0 40px;
  @media (max-width: $small-medium) {
    padding: 0 20px;
  }
}

.never-sec {
  position: relative;
  overflow: hidden;
  &::before{
    content: url('../../img/never-left-line.png');
    position: absolute;
    top: -120px;
    left: 0;
    z-index: 1;
    @media (max-width: $medium){
      left: -45px;
    }
  }
  &::after{
    content: url('../../img/never-right-line.png');
    position: absolute;
    top: 40px;
    right: -20px;
    z-index: 1;
    @media (max-width: $medium){
      top: -400px;
      right: -140px;
    }
    @media (min-width: $medium) and (max-width: 1150px) {
      top: -240px;
      right: -97px;
    }
  }
}
.selected-probes-card-mobile {
  .list-group {
    .list-group-item {
      &:last-child {
        @media (max-width: $small-medium) {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}
.static-link {
  color: #01A3EC;
  text-decoration: none;
  &:hover {
    color: #01A3EC;
  }
}
.aboutus-upperimg-new {
  position: relative;
  .about-img1 {
    margin-bottom: 93px;
    position: relative;
    z-index: 1;
  }
  .about-dots {
    position: absolute;
    left: -63px;
    bottom: 20px;
    width: 162px;
    @media (max-width: $medium) {
      display: none;
    }
  }
}
.static-email {
  color: #C9C9C9 !important;
}
.icon-dashboard-menu {
  font-size: 16px !important;
}
.privacy-address {
  p {
    margin-bottom: 8px !important;
  }
}
.static-email {
  color: #fff !important;
}

.scroll-wrapper {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 9999;
}
.scrollBtn {
  background-color: #cc3333;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 0;
  .scrol-top {
    transform: rotate(270deg);
  }
  .scrol-bottom {
    transform: rotate(90deg);
  }
}

// animation scss
@keyframes mymove {
  0%   {top: 0px;}
  25%  {top: 0px;}
  50%  {top: 60px;}
  75%  {top: 60px;}
  100% {top: 0px;}
}
@keyframes mymove2 {
  0%   {top: 0px;}
  25%  {top: 0px;}
  50%  {top: 60px;}
  75%  {top: 60px;}
  100% {top: 0px;}
}
