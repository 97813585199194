.form-mt-space {
  margin-bottom: 27px;
  position: relative;
  @media (max-width: $medium) {
    margin-bottom: 30px;
  }
  .select__single-value {
    overflow: unset;
  }
}
.max-char {
  position: absolute;
  right: 0;
  font-size: 12px;
  color: #808080;
  margin-top: 6px;
}
.form-label {
  margin: 0 0 5px 0 !important;
  font-size: 14px;
  color: $white;
  font-weight: 500;
  line-height: 18px;
  // text-transform: capitalize;
  @media (max-width: $medium) {
    font-size: 14px;
  }
  span {
    font-weight: 300;
  }
}
.select__menu-notice--no-options {
  background-color: #303039;
  font-size: 14px;
  min-height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-text {
  font-size: 14px;
  color: $grey;
  font-weight: 400;
  line-height: 18px;
  margin-left: 2px;
}
.label-required {
  color: #ff4126;
  font-size: 13px;
}
.word-count {
  font-size: 14px;
  color: $grey;
  font-weight: 300;
  line-height: 18px;
  position: absolute;
  top: 2px;
  right: 0;
}
.input-error-message {
  font-size: 12px;
  color: #cc3333;
  line-height: 14px;
  margin-top: 5px;
  display: block;
  font-weight: 400;
  @media (max-width: $extra-large) {
    font-size: 10px;
  }
}
.admin-user-error {
  position: relative;
  top: -24px;
}
// input[readonly] {
//   opacity: 0.9;
//   cursor: inherit;
// }
.form-input {
  margin: 0;
  padding: 11px 32px 10px 16px;
  border: solid 1px #353535;
  color: #fff;
  background-color: #303039;
  border-color: #303039;
  border-radius: 7px;
  font-size: 14px;
  border-style: solid;
  border-width: 1px;
  height: 44px;
  box-shadow: none !important;
  &:focus {
    background-color: transparent;
    border-color: unset;
    outline: 0;
    box-shadow: none;
    color: #fff;
    border: solid 1px #353535;
  }
}
.wrap-input {
  position: relative;
  .on-input-icon-left {
    position: absolute;
    top: 50%;
    left: 24px;
    transform: translate(-50%, -50%);
    z-index: 1;
    font-size: 17px;
    color: #aaabae;
    img {
      width: 17px;
    }
  }
  .on-input-icon-right {
    position: absolute;
    top: 50%;
    right: 3px;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 1;
  }
  .on-input-calender-icon {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 9;
    font-size: 18px;
  }
  .form-control {
    padding-left: 40px;
    &::placeholder {
      padding-left: 0px;
      @media (max-width: $medium) {
        font-size: 14px;
      }
    }
  }
}
.custom-phone-input {
  .flag-icon {
    font-size: 24px;
  }
}
.calender-icon {
  font-size: 20px;
}
.first-50 {
  padding-left: 0 !important;
  @media (max-width: 576px) {
    padding: 0;
  }
  @media (min-width: $small-medium) and (max-width: $medium) {
    padding: 0;
    width: 100%;
  }
}
.second-50 {
  padding-right: 0 !important;
  @media (max-width: 576px) {
    padding: 0;
  }
  @media (min-width: $small-medium) and (max-width: $medium) {
    padding: 0;
    width: 100%;
  }
}

// react select css
.wrap-select {
  position: relative;
  .select__menu {
    font-size: 16px;
    padding: 0;
    border-radius: 7px;
    background-color: #393a42;
    width: 100% !important;
    z-index: 2;
    overflow: hidden;
    margin: 3px 0 0 0;
    .select__menu-list {
      padding: 0;
      margin-right: 0;
      .select__option {
        font-size: 15px;
        background-color: unset;
        color: $white;
        font-weight: 400;
        line-height: 35px;
        cursor: pointer;
        padding: 8px 15px 7px 15px;
        border-bottom: 1px solid #4a4b50;
        &:last-child {
          border-bottom: 0;
        }
        &:hover {
          background-color: #4a4b50 !important;
          color: #fff;
        }
      }
    }
  }
  .select__input-container {
    color: #fff;
  }
  .on-input-icon {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  .select__control {
    background-color: #21222d;
    border-color: #21222d;
    border-radius: 7px;
    font-size: 14px;
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
    height: 44px;
    box-shadow: none !important;
    &:hover,
    &:focus {
      border-color: #21222d !important;
      box-shadow: unset !important;
    }
    svg {
      fill: #9d9d9d;
    }
    &.select__control--menu-is-open {
      .select__indicators {
        &::after {
          content: "";
          position: absolute;
          background-image: url('../../icons/arrow-up.svg');
          background-repeat: no-repeat;
          top: 4px;
          right: 0px;
          z-index: 99;
          width: 100%;
          height: 10px;
          transform: translate(-50%, 12px);
        }
      }
    }
  }
  .select__indicators {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      background-image: url('../../icons/arrow.svg');
      background-repeat: no-repeat;
      top: 4px;
      right: 0px;
      z-index: 99;
      width: 100%;
      height: 10px;
      transform: translate(-50%, 12px);
    }
    svg {
      display: none;
    }
    .select__indicator-separator {
      display: none;
    }
  }
  .css-1s2u09g-control {
    background-color: #21222d;
    border-color: #353535;
    border-radius: 3px;
    font-size: 14px;
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
    height: 44px;
    &:hover {
      border-color: #353535;
    }
  }
  .css-1pahdxg-control {
    background-color: transparent;
    box-shadow: none;
    border-color: #353535;
    height: 44px;
    font-size: 14px;
    &:hover {
      border-color: #353535 !important;
      box-shadow: none;
    }
    &:focus-within {
      border-color: 0;
      box-shadow: none;
      .css-qc6sy-singleValue {
        font-size: 14px !important;
      }
    }
  }
  .css-qc6sy-singleValue {
    color: #fff;
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-tlfecz-indicatorContainer {
    svg {
      fill: #9d9d9d;
      // width: 14px;
    }
  }
  .form-control {
    option {
      color: #000;
    }
  }
  .css-pndypt-Input[data-value] {
    color: #fff !important;
  }
  .select__value-container {
    padding-left: 41px;
    .select__single-value {
      color: #fff !important;
    }
  }
  .select__placeholder {
    color: $grey;
    font-size: 14px;
    font-weight: 400;
  }
}
//end react select css

// datepicker css
.wrap-date-input {
  border-radius: 8px;
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__month-container {
    width: 250px;
  }
  .react-datepicker {
    border: 0;
    border-radius: 10px;
    width: 250px;
  }
  input {
    height: 44px;
    margin: 0;
    padding: 11px 32px 10px 16px;
    border-radius: 7px;
    border: 0;
    background-color: #21222d;
    color: #fff;
    font-size: 14px;
    width: 100%;
    cursor: pointer;
    z-index: 1;
    position: relative;
    &:focus {
      //   background-color: transparent;
      border-color: unset;
      outline: 0;
      box-shadow: none;
      color: #fff;
      border: 0;
    }
  }
  .admin-form-input {
    padding-left: 40px;
    &::placeholder {
      color: #AAABAE;
    }
  }
  .react-datepicker__month-wrapper {
    .react-datepicker__month-text--keyboard-selected {
      background-color: #4a4b50;
    }
  }
  .react-datepicker__month .react-datepicker__month-text,
  .react-datepicker__month .react-datepicker__quarter-text {
    // background-color: #393A42;
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 6px 22px;
    padding-top: 7px;
    color: #fff;
    font-size: 12px;
    border-radius: 3px;
    &:hover {
      background-color: #4a4b50;
      cursor: pointer;
    }
  }
  .react-datepicker__header {
    background-color: #393a42;
    color: #fff;
    border-color: #4a4b50;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    .react-datepicker__current-month {
      color: #fff;
    }
    .react-datepicker__day-names {
      .react-datepicker__day-name {
        color: #fff;
      }
    }
  }
  .react-datepicker__month {
    background-color: #393a42;
    padding: 0.4rem;
    margin: 0;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }
  .react-datepicker__day--disabled {
    color: #8b8888 !important;
  }
  .react-datepicker__da {
    color: #fff !important;
  }
  .react-datepicker__day--selected {
    border-radius: 0.3rem;
    background-color: #cc3333;
    color: #fff;
  }
  .react-datepicker__day--keyboard-selected {
    background-color: #CC3333;
    color: #fff;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: #fff;
    &:hover {
      background-color: #cc3333;
      color: #fff;
    }
  }
  .react-datepicker__triangle {
    &::before,
    &::after {
      display: none;
    }
  }
  .react-datepicker__navigation--previous {
    top: 0;
  }
  .react-datepicker__navigation--next {
    top: 0;
  }
  .react-datepicker-popper {
    z-index: 9999;
  }
  .react-datepicker__month--disabled {
    opacity: 0.5;
  }
}
.react-tel-input {
  input {
    padding-left: 58px !important;
    border: solid 0 #21222d !important;
    background-color: #21222d !important;
    width: 100% !important;
    box-shadow: unset !important;
    border-radius: 8px !important;
    height: 44px !important;
    &:focus {
      background-color: transparent;
      border-color: unset;
      outline: 0;
      box-shadow: none;
      color: #fff;
      border: solid 0 #21222d;
    }
  }
  input[disabled] {
    opacity: 0.6;
    cursor: inherit;
  }
  .flag-dropdown {
    background-color: #21222d !important;
    border: 0 solid #21222d !important;
    border-right: 0 !important;
    border-radius: 8px 0 0 8px;
    &.open {
      border-radius: 8px;
      width: 100%;
      .selected-flag {
        background: #21222d !important;
        border-radius: 8px 0 0 8px;
        &:hover,
        &:focus {
          background: #21222d !important;
        }
      }
    }
    .selected-flag {
      &:hover,
      &:focus {
        background-color: #21222d !important;
        border-radius: 8px 0 0 8px;
      }
    }
  }
  .country-list {
    background-color: #393a42 !important;
    color: #fff;
    border-radius: 7px;
    width: 100%;
    margin: 3px 0 0 0;
    li {
      cursor: pointer;
      &:hover {
        background-color: #4a4b50 !important;
        cursor: pointer;
      }
      &[aria-selected='true'] {
        background-color: #4a4b50 !important;
      }
    }
    .country {
      padding: 8px 15px 7px 15px;
      height: 51px;
      border-bottom: 1px solid #4a4b50;
      display: flex;
      align-items: center;
      &:last-child {
        border-bottom: 0;
      }
    }
  }
  .selected-flag {
    .flag {
      left: 15px !important;
    }
    .arrow {
      margin-top: -3px !important;
      left: 21px !important;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #aaabae;
    }
    .arrow.up {
      border-bottom: 6px solid #aaabae;
    }
  }
}

.form-control {
  height: 44px;
  margin: 0;
  padding: 11px 32px 10px 16px;
  border-radius: 7px;
  border: 0;
  background-color: #21222d;
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-weight: 400;
  box-shadow: none;
  transition: none !important;
  @media (max-width: $medium) {
    font-size: 14px;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #21222d inset !important;
    -webkit-text-fill-color: #fff !important;
    font-size: 14px;
  }
  &:hover,
  &:focus {
    -webkit-box-shadow: 0 0 0 30px #21222d inset !important;
    color: $white !important;
    font-size: 14px;
    background-color: unset;
    border: 0;
    @media (max-width: $medium) {
      font-size: 14px;
    }
  }
  &::placeholder {
    color: $grey;
    font-size: 14px;
    @media (max-width: $medium) {
      font-size: 14px;
    }
  }
}

.payment-ach-option-stripe {
  div {
    margin-bottom: 27px;
    label {
      margin: 0 0 5px 0 !important;
      font-size: 14px;
      color: #ffffff;
      font-weight: 500;
      line-height: 18px;
      display: block;
    }
    input {
      background-color: #21222d !important;
      border: 0 !important;
      height: 44px !important;
      border-radius: 7px !important;
      width: 100% !important;
      padding: 11px 16px 10px 16px;
      caret-color: $grey;
      &:focus {
        background-color: transparent;
        border-color: unset;
        outline: 0;
        box-shadow: none;
        color: #fff;
        border: 0;
      }
    }
  }
  .Input--empty {
    &::placeholder {
      color: $grey;
    }
  }
}

.stripe-card-number {
  background-color: #21222d;
  height: 44px !important;
  padding: 13px 16px;
  color: #fff;
  border-radius: 7px;
  &::placeholder {
    color: $grey;
    font-size: 14px;
    @media (max-width: $medium) {
      font-size: 14px;
    }
  }
}
.react-datepicker-popper[data-placement^='bottom'] {
  padding-top: 3px;
}

// custom-radio with privacy policy and terms and condition links
.custom-checkbox {
  input {
    display: none;
    &:checked {
      & + span {
        &:before {
          background-image: url('../../icons/checkbox-tick.svg') !important;
          background-repeat: no-repeat;
          background-color: #cc3333 !important;
          border-color: #cc3333 !important;
          background-size: 64%;
          background-position: center;
        }
        &:after {
          display: block;
        }
      }
    }
    &:disabled {
      & + span {
        &:before {
          background-color: #999;
          border-color: #999;
        }
      }
    }
  }
  .custom-checkbox-wrap {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    color: #aaabae;
    display: block;
    margin-bottom: 27px;
    @media (min-width: $small-medium) and (max-width: $medium) {
      line-height: 18px;
    }
    .custom-checkbox-link {
      font-weight: 500;
      color: #fff;
      margin: 0 3px;
      text-decoration: underline;
    }
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: -1px;
      border-radius: 2px !important;
      box-shadow: none;
      width: 19px;
      height: 19px;
      border: 1px solid;
    }
  }
}

.text-red {
  color: #cc3333;
  font-size: 15px;
}
// end custom-radio with privacy policy and terms and condition links
.signup-checkbox {
  .custom-checkbox-wrap {
    line-height: 20px;
    &::before {
      top: 2px;
    }
  }
  .custom-checkbox-link {
    display: inline-flex;
  }
}
.bgcolor-input-select {
  .select__control,
  .flag-dropdown,
  .form-control {
    background-color: #303039 !important;
    padding-left: 15px;
    &:hover,
    &:focus {
      box-shadow: unset !important;
      background-color: #303039 !important;
    }
  }
  .select__control,
  .flag-dropdown {
    padding-left: 0 !important;
    &.open {
      .selected-flag {
        &:hover, &:focus {
          background-color: #303039 !important;
        }
      }  
    }
    .selected-flag {
      &:hover, &:focus {
        background-color: #303039 !important;
      }
    }
  }
}

// multi select wrap css
.multi-select-wrap {
  .form-input {
    padding: 8px 16px 7px 12px;
    align-items: center;
    height: auto;
    input {
      &::placeholder {
        color: $grey;
      }
    }
  }
  .react-tagsinput-input {
    color: #fff;
    font-size: 14px;
    margin-bottom: 0;
    width: auto;
  }
  .react-tagsinput--focused {
   border-color: transparent !important; 
  }
  .react-tagsinput-tag {
    background-color: #0F101A;
    border-radius: 4px;
    border: 1px solid #CC3333;
    color: #fff;
    margin-bottom: 9px;
    margin-right: 9px;
    padding: 4px 9px;
    text-transform: uppercase;
    .react-tagsinput-remove {
      cursor: pointer;
      font-weight: normal;
      font-size: 18px;
      color: #CC3333;
      margin-left: 5px;
    }
  }
}
.filter-sec-bottom {
  @media (max-width: $small-medium) {
    margin-bottom: 80px;
  }
  @media (min-width: $small-medium) and (max-width: $medium) {
    margin-bottom: 37px;
  }
}
.sign-up-note {
  border-radius: 9px;
  background: rgba(204, 51, 51, 0.1);
  padding: 13px;
  margin: -4px 0 30px 0;
  p {
    color: #AAABAE;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    margin-bottom: 0;
    span {
      color: #fff;
      font-weight: 500;
    }
  }
}
.using-custom-checkbox {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: #fff;
  .using-custom-checkbox-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 15px;
    font-weight: 400;
    line-height: 17px;
    margin: 0 44px 27px 0;
    p {
      position: relative;
      top: -1px;
      width: 19px;
      height: 19px;
      border-radius: 2px;
      border: 1px solid #A8C5DA;
      margin-right: 9px;
    }
    &.checked {
      span {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 11px;
        height: 11px;
        display: flex;
        justify-content: center;
        align-items: center;
        // background-color: #A8C5DA !important;
        border-radius: 2px;
      }
    }
  }
}
