.pricing-sec {
  margin: 0 53px;
  @media (max-width: $medium) {
    margin: 0 20px;
  }
  .pricing-wrap {
    background-image: url('../../img/pricing-bg.png');
    height: 294px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 36px 0;
    border-radius: 18px;
    text-align: center;
    padding: 27px 0 27px;
    position: relative;
    @media (max-width: $medium) {
      height: auto;
      padding: 15px 0 14px;
      margin: 36px 0 20px 0;
    }
    @media (min-width: $small-medium) and (max-width: $medium) {
      padding: 27px 0 27px;
    }
    .pricing-left-bg {
      position: absolute;
      top: 1px;
      left: 0;
      border-top-left-radius: 18px;
      @media (max-width: $medium) {
        width: 50px;
      }
    }
    .pricing-right-bg {
      position: absolute;
      bottom: 1px;
      right: 0;
      transform: rotate(180deg);
      border-top-left-radius: 18px;
      @media (max-width: $medium) {
        width: 50px;
      }
    }
    .pricing-title {
      font-size: 20px;
      line-height: 18px;
      font-weight: 400;
      color: $white;
      font-family: 'Caveat', sans-serif;
    }
    .pricing-subtitle {
      margin: 22px 0 18px 0;
      position: relative;
      @media (max-width: $medium) {
        margin: 6px 0 17px 0;
      }
      h3 {
        font-size: 36px;
        line-height: 41px;
        font-weight: 600;
        color: $white;
        z-index: 1;
        position: relative;
        @media (max-width: $medium) {
          font-size: 20px;
          line-height: 26px;
          padding: 0 24px;
        }
        @media (min-width: $small-medium) and (max-width: 1150px) {
          font-size: 32px;
        }
      }
      .custom-circle {
        position: absolute;
        top: -33px;
        margin-left: 42px;
        z-index: 0;
        @media (max-width: $medium) {
          top: 20px;
          margin-left: 0;
          left: 79px;
          width: 190px;
          height: 41px;
        }
        @media (min-width: $small-medium) and (max-width: $medium) {
          top: -12px;
          right: 161px;
          left: auto;
          width: 180px;
          height: 45px;
        }
      }
    }
    .pricing-desc {
      font-size: 16px;
      line-height: 26px;
      font-weight: 400;
      color: rgba(201, 201, 201, 0.7);
      margin-bottom: 67px;
      @media (max-width: $medium) {
        font-size: 14px;
        line-height: 22px;
        padding: 0 20px;
        margin-bottom: 29px;
      }
      @media (min-width: $small-medium) and (max-width: $medium) {
        margin-bottom: 60px;
      }
    }
  }
}

// switch toggle css
.switch-sec {
  display: inline-flex;
  align-items: center;
  position: relative;
  .switch-wrap {
    .tabs {
      width: 249px;
      height: 44px;
      margin: auto;
      border-radius: 36px;
      font-size: 14px;
      line-height: 17px;
      font-weight: 700;
      color: #ffffff;
      display: flex;
      align-items: center;
      position: relative;
      background-color: #fff;
      padding: 0 3px;
      * {
        z-index: 2;
      }
      @media (max-width: $medium) {
        max-width: 210px;
        height: 40px;
      }
    }

    .tab {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 99px;
      cursor: pointer;
      transition: color 0.15s ease-in;
      text-align: center;
      font-size: 14px;
      line-height: 17px;
      font-weight: 700;
      text-transform: uppercase;
      width: 127px;
      color: #000;
    }

    .notification {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      height: 2rem;
      margin-left: 0.75rem;
      border-radius: 50%;
      background-color: #000;
      transition: 0.15s ease-in;
    }

    input[type='radio'] {
      display: none;
      &:checked {
        & + label {
          color: #fff;
          & > .notification {
            background-color: #000;
            color: #fff;
          }
        }
      }
    }

    input[id='radio-1'] {
      &:checked {
        & ~ .glider {
          transform: translateX(0);
        }
      }
    }

    input[id='radio-2'] {
      &:checked {
        & ~ .glider {
          transform: translateX(96%);
        }
      }
    }

    input[id='radio-3'] {
      &:checked {
        & ~ .glider {
          transform: translateX(200%);
        }
      }
    }

    .glider {
      position: absolute;
      display: flex;
      height: 38px;
      width: 124px;
      background-color: #000;
      z-index: 1;
      border-radius: 99px; // just a high number to create pill effect
      transition: 0.25s ease-out;
      @media (max-width: $medium) {
        height: 34px;
        width: 105px;
      }
    }

    @media (max-width: 700px) {
      // .tabs {
      //   transform: scale(0.6);
      // }
    }
  }
  .discount-sec {
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    color: $white;
    position: absolute;
    right: -157px;
    @media (max-width: $medium) {
      margin-left: 0;
      right: 198px;
      top: 25px;
    }
    .discount-text {
      @media (max-width: $medium) {
        position: absolute;
        top: -35px;
        left: 191px;
        font-size: 11px;
        line-height: 13px;
        width: 68px;
      }
      @media (min-width: $small-medium) and (max-width: $medium) {
        top: -22px;
        left: 231px;
        font-size: 14px;
        width: 90px;
      }
    }
    .custom-mark {
      position: absolute;
      top: -47px;
      right: 78px;
      @media (max-width: $medium) {
        top: -54px;
        left: 171px;
        width: 41px;
        height: 18px;
        transform: rotate(-18deg);
      }
      @media (min-width: $small-medium) and (max-width: $medium) {
        top: -63px;
        left: 171px;
        width: 82px;
        height: 38px;
        transform: rotate(-11deg);
      }
    }
  }
}

// select-package-sec css
.select-package-sec {
  background-color: #21222d;
  margin: 0;
  padding: 27px;
  border-radius: 18px;
  position: relative;
  @media (max-width: $medium) {
    padding: 20px;
  }
}
.no-probes-sec {
  .no-probes-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $medium) {
      display: unset;
    }
    .no-probes-left {
      .no-probes-title {
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        color: $white;
        margin-bottom: 18px;
        @media (max-width: $medium) {
          font-size: 15px;
          line-height: 18px;
          margin-bottom: 13px;
        }
      }
      .probs-dollar {
        font-weight: 700;
        font-size: 43px;
        line-height: 50px;
        color: $white;
        @media (max-width: $medium) {
          font-size: 34px;
          line-height: 39px;
        }
        .default-month {
          font-weight: 400;
          font-size: 18px;
          line-height: 21px;
          color: $grey;
          margin-left: 5px;
          @media (max-width: $medium) {
            font-size: 13px;
            line-height: 15px;
            margin-left: 4px;
          }
        }
        .annually {
          background-color: rgba(67, 160, 71, 0.2);
          padding: 8px 18px;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          color: #43a047;
          border-radius: 16px;
          margin-left: 7px;
          position: relative;
          top: -10px;
          @media (max-width: $medium) {
            background-color: rgba(67, 160, 71, 0.2);
            padding: 6px 15px;
            font-size: 13px;
            line-height: 14px;
            top: -8px;
          }
        }
      }
    }
  }
}
.probes-select-package {
  position: absolute;
  top: 48px;
  right: 27px;
  @media (max-width: $medium) {
    position: unset;
    margin-top: 30px;
    .select-package-btn {
      width: 100% !important;
      font-size: 14px !important;
    }
  }
  @media (min-width: $small-medium) and (max-width: $medium) {
    position: absolute;
    top: 30px;
    right: 27px;
    margin-top: 0;
  }
  .select-package-btn {
    width: 231px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    @media (min-width: $small-medium) and (max-width: $medium) {
      min-width: 175px;
    }
  }
}
.package-heading {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: rgba(123, 123, 123, 0.8);
  margin: 32px 0 15px;
}
.addon-sec {
  position: relative;
  background-color: #303039;
  padding: 22px;
  border-radius: 18px;
  overflow: hidden;
  .form-check-label {
    margin-left: 18px;
    @media (max-width: $medium) {
      margin-left: 14px;
      font-size: 14px;
      line-height: 20px;
      top: 2px;
      margin-right: 32px;
    }
  }
  .addon-infor {
    .addon-des {
      background-color: rgba(204, 51, 51, 0.1);
      padding: 18px;
      border-radius: 9px;
      margin: 14px 28px 0 38px;
      @media (max-width: $medium) {
        margin: 14px 0 0 0;
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: $grey;
        @media (max-width: $small) {
          font-size: 14px;
          line-height: 28px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
.subprobs-dollar {
  font-weight: 700;
  font-size: 32px;
  line-height: 37px;
  color: $white;
  margin: 32px 28px 0 38px;
  @media (max-width: $medium) {
    font-size: 34px;
    line-height: 39px;
    margin: 14px 25px 0 33px;
  }
  .default-month {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $grey;
    margin-left: 5px;
    @media (max-width: $medium) {
      font-size: 13px;
      line-height: 15px;
      margin-left: 4px;
    }
  }
  .annually {
    background-color: rgba(67, 160, 71, 0.2);
    padding: 7px 15px;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #43a047;
    border-radius: 16px;
    margin-left: 7px;
    position: relative;
    top: -7px;
    @media (max-width: $medium) {
      margin-left: 0;
      max-width: 73px;
      margin-top: 11px;
      display: block;
    }
    @media (min-width: $small-medium) and (max-width: $medium) {
      margin-left: 5px;
      display: unset;
    }
  }
}
.package-tag {
  position: absolute;
  top: 20px;
  right: -51px;
  background-color: #ffffff;
  width: 168px;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(46deg);
  @media (max-width: $medium) {
    top: 6px;
    right: -26px;
    width: 94px;
    height: 33px;
  }
  p {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: black;
    @media (max-width: $medium) {
      font-size: 12px;
      line-height: 14px;
    }
  }
}

// secure-payment-sec css
.secure-payment-sec {
  text-align: center;
  margin: 89px 0 69px 0;
  @media (max-width: $medium) {
    margin: 55px 0 69px 0;
  }
  .secure-payment-title {
    font-weight: 700;
    font-size: 27px;
    line-height: 30px;
    color: $white;
    @media (max-width: $medium) {
      font-size: 20px;
      line-height: 34px;
    }
  }
  .secure-payment-subtitle {
    font-weight: 400;
    font-size: 15px;
    line-height: 29px;
    color: $grey;
    margin: 13px 0 0 0;
    @media (max-width: $medium) {
      font-size: 14px;
      line-height: 26px;
      margin: 10px 0 0 0;
    }
  }
  .secure-payment-wrapper {
    margin: 43px 0 0;
    @media (max-width: $medium) {
      margin: 53px 0 0;
    }
    @media (min-width: $small-medium) and (max-width: $medium) {
      margin: 40px 0 0;
    }
  }
}
.secure-payment-cards {
  gap: 21px;
  .secure-payment-card-item {
    background-color: #21222d;
    padding: 24px 17px;
    text-align: left;
    border-radius: 18px;
    width: 32%;
    margin-bottom: 24px;
    @media (max-width: $medium) {
      width: 100%;
      margin-bottom: -4px;
    }
    span {
      font-size: 26px;
    }
    .payment-card-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: $white;
      margin: 22px 0 10px 0;
      text-transform: uppercase;
      @media (max-width: $medium) {
        margin: 26px 0 14px 0;
      }
    }
    .payment-card-des {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: $grey;
    }
  }
}

// subscription footer css
.subscription-footer-sec {
  border-top: 1px solid #2c2d33;
  padding: 20px 53px;
  @media (max-width: $medium) {
    padding: 20px;
    text-align: center;
  }
}
.subscription-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: $medium) {
    display: flex;
    flex-direction: column-reverse;
  }
  .footer-copy-right {
    font-weight: 400;
    font-size: 13px;
    line-height: 21px;
    color: rgba(201, 201, 201, 0.6);
    @media (max-width: $medium) {
      margin-top: 10px;
      line-height: 24px;
    }
  }
}

// payment successful css
.pricing-successful-sec {
  max-width: 885px;
  width: 100%;
  padding: 89px 0 89px;
  margin: auto;
  min-height: calc(100vh - 145px);
  @media (max-width: $medium) {
    padding: 30px 20px 50px;
    min-height: unset;
  }
}
.successful-wrap-sec {
  background-color: #21222d;
  border-radius: 18px;
  padding: 44px;
  text-align: center;
  @media (max-width: $medium) {
    padding: 30px 20px 20px 20px;
  }
  .payment-successful-icon {
    margin-bottom: 11px;
  }
  .successful-title {
    font-weight: 600;
    font-size: 27px;
    line-height: 31px;
    color: $white;
    @media (max-width: $medium) {
      font-size: 22px;
      line-height: 26px;
    }
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: rgba(201, 201, 201, 0.7);
    margin: 13px 0 35px 0;
    @media (max-width: $medium) {
      font-size: 14px;
      margin: 14px 0 30px 0;
    }
  }
}
.successful-listing-wrapper {
  background-color: #303039;
  border-radius: 7px;
  padding: 36px;
  @media (max-width: $medium) {
    padding: 20px;
  }
}
.successful-listing {
  .list-group-item {
    background-color: transparent;
    border: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0 0 22px 0;
    @media (max-width: $medium) {
      display: unset;
      text-align: left;
      margin: 0 0 30px 0;
    }
    &:last-child {
      margin: 0;
    }
    .listing-item-left {
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: $grey;
      margin: 0;
      @media (max-width: $medium) {
        font-size: 14px;
        line-height: 24px;
      }
      @media (min-width: $small-medium) and (max-width: $medium) {
        line-height: 28px;
      }
    }
    .listing-item-right {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: $white;
      margin: 0;
      // text-transform: capitalize;
      @media (max-width: $medium) {
        font-size: 14px;
        line-height: 24px;
      }
    }
    .payment-successful-bank {
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      color: $white;
      margin: 0;
      @media (max-width: $medium) {
        font-size: 14px;
        line-height: 24px;
    }
  }
  }
}

.successful-note {
  background-color: rgba(204, 51, 51, 0.1);
  padding: 14px 36px;
  border-radius: 9px;
  margin-top: 18px;
  text-align: left;
  @media (max-width: $medium) {
    padding: 20px;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: $white;
    font-style: italic;
    margin: 0;
    @media (max-width: $medium) {
      font-size: 14px;
      line-height: 24px;
    }
    span {
      font-weight: 700;
      font-size: 14px;
      line-height: 26px;
      color: #cc3333;
    }
  }
}
.successful-to-dashboard {
  margin-top: 44px;
  display: block;
  text-align: -webkit-right;
  @media (max-width: $medium) {
    margin-top: 30px;
  }
  .successful-dashboard-btn {
    width: 213px;
    height: 53px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    @media (max-width: $medium) {
      width: 100%;
    }
  }
}
.succesull-main-sec {
  background-image: url('../../img/onboarding-bottom-left.png');
  background-position: left bottom;
  background-repeat: no-repeat;
  position: relative;
  @media (max-width: $medium) {
    background-image: unset;
  }
}
